import React from "react";
import StripeElementWrapper from "./StripeElementWrapper";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import amexIcon from "../../assets/amex.svg";
import mastercardIcon from "../../assets/mastercard.svg";
import visaIcon from "../../assets/visa.svg";
import discoverIcon from "../../assets/discover.svg";
import unknownCardIcon from "../../assets/credit-card.svg";

const cardBrandsAssets = {
  amex: amexIcon,
  mastercard: mastercardIcon,
  visa: visaIcon,
  discover: discoverIcon,
  unknown: unknownCardIcon
};

export function StripeCardNumberField({ onChange, label, ...props }) {
    const [brand, setBrand] = React.useState("unknown");

    const handleOnChange = (eventProps) => {
        setBrand(eventProps.brand);
        onChange && onChange(eventProps);
    }

    return (
        <StripeElementWrapper
            label={label || `Card Number`}
            stripeComponent={CardNumberElement}
            InputProps={{
                endAdornment: (
                    <img
                        alt="Card brand"
                        src={cardBrandsAssets[brand] || cardBrandsAssets.unknown}
                        style={{
                            height: "30px",
                            marginBottom: "2px",
                            marginLeft: "5px"
                        }}
                    />
                )
            }}
            onChange={handleOnChange}
            {...props}
        />
    )
}

export function StripeCardExpiryField({ label, ...props }) {
    return (
        <StripeElementWrapper label={label || 'Expiry date'} stripeComponent={CardExpiryElement} {...props} />
    )
}

export function StripeCardCVCField({ label, ...props }) {
    return (
        <StripeElementWrapper label={label || "CVC"} stripeComponent={CardCvcElement} {...props} />
    )
}