import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Drawer, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import ValidateEmailModal from '@nvapps/common/components/modals/ValidateEmailModal';
import useDialogOpener from '@nvapps/common/hooks';
import Logo from '@nvtracker/common/components/Logo';
import MHidden from '@nvtracker/common/components/ui/MHidden';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useCallApi } from '../hooks';
import { useAuthenticationActions } from '../store/authentication';
import { useProfilePhoto, useUserActions, useUserProfile } from '../store/user';
import NavSection from './NavSection';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[200]
}));

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkIcon = styled(Icon)({
    animation: `${blink} 1s linear infinite`,
});

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {

    const { pathname } = useLocation();
    const { profile } = useUserProfile();
    const { photo } = useProfilePhoto();
    const { t } = useTranslation();
    const { logout } = useAuthenticationActions();
    const { resendValidationEmail, validateEmail } = useUserActions();
    const [callApi, isBusy] = useCallApi({ });

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const navConfig = [{
        title: t('devices'),
        path: '/app/devices',
        icon: getIcon('fluent:device-meeting-room-16-filled')
    },
    {
        title: t('purchases'),
        path: '/app/purchases',
        icon: getIcon('icon-park-outline:buy')
    },
    {
        title: t('events'),
        path: '/app/events',
        icon: getIcon('bx:bx-calendar-exclamation')
    }]

    const validationDialog = useDialogOpener();

    const handleValidation = async validationCode =>
    {
        const res = await callApi(validateEmail(validationCode));
        if (res !== false) validationDialog.handleCloseDialog();
    }

    const handleResendValidation = () =>
    {
        if (isBusy === false) callApi(resendValidationEmail());
    }

    const renderContent = (
        <React.Fragment>
            <Box sx={{ px: 2.5, py: 3 }}>
                <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                    <Logo />
                </Box>
            </Box>
            <Box sx={{ mb: 5, mx: 2.5 }}>
                <AccountStyle>
                    <Avatar src={photo} alt="photoURL" />
                    <Box sx={{ ml: 2, overflow: 'hidden' }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                            {profile.name || profile.userName}
                        </Typography>
                        {profile.name &&
                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                {profile.userName}
                            </Typography>}
                    </Box>
                </AccountStyle>
                {profile.validated === false &&
                <Box mt={2}>
                    <Tooltip title={<Trans>validateEmailInfo</Trans>}>
                        <Button variant='text' onClick={() => validationDialog.handleOpenDialog()}  color='error' size="small" fullWidth startIcon={<BlinkIcon icon="akar-icons:circle-alert" height={24} />}>
                            <Trans>validateEmail</Trans>
                        </Button>
                    </Tooltip>
                </Box>}
            </Box>
            <NavSection navConfig={navConfig} />
            <Box sx={{ flexGrow: 1 }} />
            <Box p={2.5}>
                <Button variant='outlined' onClick={() => logout()}  color='primary' fullWidth>
                    <Trans>logout</Trans>
                </Button>
            </Box>
            <ValidateEmailModal
                open={validationDialog.open}
                isBusy={isBusy}
                submitButton={<Trans>submit</Trans>}
                cancelButton={<Trans>cancel</Trans>}
                resendText={<Trans>resendEmail</Trans>}
                title={<Trans>validateEmail</Trans>}
                inputLabel={<Trans>validationCode</Trans>}
                onSubmit={handleValidation}
                onClose={validationDialog.handleCloseDialog}
                onResend={handleResendValidation} />
        </React.Fragment>
    );

    return (
        <RootStyle>
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
