import { createSlice } from '@reduxjs/toolkit'
import * as nvtrackerApi from '../api/nvtracker';
import { useReduxFetch } from '../hooks';
import { registerSubscription } from '../mqtt';
import { PurchasesStatusEnum } from '@nvtracker/common/helpers';

const initialState = {
    isFetching: false,
    fetched: false,
    error: null,
    byId: {},
    allIds: []
};

const purchases = createSlice({
    name: 'purchases',
    initialState,
    reducers: {
        fetchStart(state) {
            state.isFetching = true;
        },
        fetchFailed(state, action) {
            state.isFetching = false;
            state.error = action.payload;
        },
        fetchSuccess(state, action) {
            state.isFetching = false;
            state.fetched = true;
            state.allIds = action.payload.map(d => d.id);
            state.byId = Object.fromEntries(action.payload.map(d => [d.id, d]));
        },
        updatePurchase(state, action) {
            state.byId[action.payload.id] = action.payload;
        },
        addPurchase(state, action) {
            state.allIds.push(action.payload.id);
            state.byId[action.payload.id] = action.payload;
        }
    }
});

function fetchPurchases(refetch = false) {
    return async (dispatch, getState) => {
        const state = getState();
        if (state.purchases.isFetching || (refetch === false && state.purchases.fetched)) return;
        dispatch(purchases.actions.fetchStart());
        nvtrackerApi.getPurchases().then(
            res => {
                dispatch(purchases.actions.fetchSuccess(res.data));
                registerSubscription("purchases", function (topic, data) {
                    switch (data.action) {
                        case "add":
                            return dispatch(purchases.actions.addPurchase(data.payload));
                        case "update":
                            return dispatch(purchases.actions.updatePurchase(data.payload));
                        default:
                            throw new Error("Invalid action");
                    }
                });
            },
            err => dispatch(purchases.actions.fetchFailed(err))
        );
    }
}

export function getPurchase(state, id) {
    const purchases = state.purchases;
    const purchase = purchases.byId[id];
    return Boolean(purchase) && purchase.status !== PurchasesStatusEnum.ANNULLED ? purchase : null;
}

export function getPurchases(state) {
    const purchases = state.purchases;
    return purchases.allIds.map(id => purchases.byId[id]).filter(p => p.status !== PurchasesStatusEnum.ANNULLED);
}

export function isFetchingPurchases(state) {
    return state.purchases.isFetching;
}

export function purchasesFetchError(state) {
    return state.purchases.error;
}

export const usePurchases = (selector = getPurchases) => {
    return useReduxFetch(fetchPurchases, isFetchingPurchases, selector, purchasesFetchError);
}

export const usePurchase = (id) => {
    return usePurchases(state => getPurchase(state, id));
}

export default purchases.reducer;