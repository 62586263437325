import { Avatar, ListItemAvatar } from "@mui/material";

export default function ListItemIconAvatar({ iconComponent }) {
    const IconComp = iconComponent;
    return (
        <ListItemAvatar>
            <Avatar style={{background: "rgba(0,0,0,.66)", color: "rgba(255,255,255, 0.66)"}}>
                <IconComp />
            </Avatar>
        </ListItemAvatar>
    )
}
