import PaymentModalBase from "@nvapps/common/components/modals/PaymentModalBase";
import React from "react";
import { useTranslation } from "react-i18next";
import { getPaymentDetails } from "../api/nvtracker";

export default function PaymentModal({ purchase, ...props }) {

    const { t } = useTranslation();

    const translations =
    {
        mbPayment: t('MBPayment'),
        entity: t('entity'),
        reference: t('reference'),
        value: t('value'),
        paymentDeadline: t('paymentDeadline'),
        cancel: t('cancel'),
        creditDebitCardPayment: t('creditDebitCardPayment'),
        pay: t('pay'),
        cardNumber: t('cardNumber'),
        expiryDate: t('expiryDate')
    }


    if (!purchase) return false;
    return (
        <PaymentModalBase
            paymentType={purchase.paymentType}
            currency={purchase.currency}
            translations={translations}
            fetchPaymentDetails={() => getPaymentDetails(purchase)}
            {...props} />
    );
}

