import {
    Box,
    Button, DialogActions, DialogContent, DialogTitle, Link, TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ButtonWithProgress from "../ui/ButtonWithProgress";
import Dialog from "../ui/Dialog";

export default function ValidateEmailModal({
    open,
    isBusy,
    title = 'Validate email',
    submitButton = 'Submit',
    cancelButton = 'Cancel',
    inputLabel = 'Validation code',
    resendText = 'Resend',
    onSubmit,
    onClose,
    onResend
}) {

    const [validationCode, setValidationCode] = useState("");
    const validationCodeError = validationCode.length === 0;

    const handleSave = () => onSubmit && onSubmit(validationCode);

    useEffect(() => {

        if (open === false) setValidationCode('');
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <TextField
                    required
                    fullWidth
                    margin="dense"
                    label={inputLabel}
                    type="text"
                    autoFocus
                    variant="filled"
                    inputProps={{
                        maxLength: 8,
                        sx: {
                            textAlign: 'center',
                            fontSize: '2em',
                            textTransform: 'uppercase'
                        }
                    }}
                    value={validationCode}
                    onChange={e => setValidationCode((e.target.value || '').trim())}
                    error={validationCodeError} />
                    {Boolean(onResend) && <Box mt={1}>
                        <Link href="#" color="primary" onClick={onResend}>
                            {resendText}
                        </Link>
                    </Box>}
            </DialogContent>
            <DialogActions>
                <ButtonWithProgress
                    onClick={handleSave}
                    color="primary"
                    loading={isBusy}
                    disabled={validationCodeError}>
                    {submitButton}
                </ButtonWithProgress>
                <Button onClick={onClose} color="secondary" disabled={isBusy}>
                    {cancelButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

