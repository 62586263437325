import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const TabIconSyles = styled(Tab)(({ theme }) => ({
    flexDirection: 'row',
    minHeight: 48,
    padding: 0,
    minWidth: 48,
    paddingRight: 5,
    ':not(:last-of-type)':
    {
        marginRight: 40
    },
    ' > :first-of-type':
    {
        marginBottom: 0,
        marginRight: 8
    }
}));

export default function TabIcon({ direction = 'horizontal', ...props }) {
    return direction == 'horizontal' ? <TabIconSyles {...props} /> : <Tab {...props} />;
}
