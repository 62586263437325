import React from "react";
import { alpha, useTheme } from "@mui/material/styles";

const StripeInput = React.forwardRef((props, ref) => {

    const { component: Component, ...other } = props;
    const theme = useTheme();
    const [mountNode, setMountNode] = React.useState(null);

     React.useImperativeHandle(ref, () => ({
        focus: () => {
            mountNode.focus()
        },
        // hiding the value e.g. react-stripe-elements
    }), [mountNode]);


   /* React.useImperativeHandle(
        inputRef,
        () => ({
            focus: () => mountNode.focus()
        }),
        [mountNode]
    );*/

    return (
        <Component
            onReady={setMountNode}
            options={{
                style: {
                    base: {
                        color: theme.palette.text.primary,
                        fontSize: `${theme.typography.fontSize}px`,
                        fontFamily: theme.typography.fontFamily,
                        "::placeholder": {
                            color: alpha(theme.palette.text.primary, 0.42)
                        }
                    },
                    invalid: {
                        color: theme.palette.text.primary
                    }
                }
            }}
            {...other} />
    );
});

export default StripeInput;

/*export default function StripeInput(props) {
    const {
        component: Component,
        inputRef,
        ...other
    } = props;

    const theme = useTheme();
    const [mountNode, setMountNode] = React.useState(null);

    React.useImperativeHandle(
        inputRef,
        () => ({
            focus: () => mountNode.focus()
        }),
        [mountNode]
    );

    return (
        <Component
            onReady={setMountNode}
            options={{
                style: {
                    base: {
                        color: theme.palette.text.primary,
                        fontSize: `${theme.typography.fontSize}px`,
                        fontFamily: theme.typography.fontFamily,
                        "::placeholder": {
                            color: alpha(theme.palette.text.primary, 0.42)
                        }
                    },
                    invalid: {
                        color: theme.palette.text.primary
                    }
                }
            }}
            {...other} />
    );
}
*/