export const DevicesPlans = {
    Zero: "Zero",
    Premium: "Premium"
}

export const DevicesStatus = {
    Active: "Active"
}

export function isDeviceActive(device)
{
    return device && device.status === DevicesStatus.Active;
}

export function canRenewDevice(device)
{
    return !device || (!device.hasPendingPurchase && isDeviceActive(device));
}