import { format as formatDate } from "date-fns";
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';

import jstz from 'jstz';

export const locales = { pt, es, fr, en };

export function getFormatedDate(datetime, format = 'Pp', locale = 'pt')
{
    return datetime ? formatDate(datetime instanceof Date ? datetime : new Date(datetime), format, { locale: locales[(locale || 'pt' ).toLowerCase()] }) : 'N/D';
}

export function getLocalTimeZone()
{
    const timezone = jstz.determine();
    return timezone.name();
}