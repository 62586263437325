import { Icon } from "@iconify/react";
import {
    Box, CircularProgress,
    Divider, Stack,
    Typography
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useFetchV2 } from "../../../hooks";
import { getCurrencySymbol } from "../../../utils";
import { locales } from "../../../utils/dateUtils";
import { Field, FieldList } from "../Fields";
import { MultibancoIcon } from "../Icons";

export default function MultibancoPaymentInfo({ fetchPaymentDetails, translations, locale = 'pt', currency, ...props }) {

    const [payment, isLoading] = useFetchV2(() => fetchPaymentDetails(), []);
    const { entity, reference, value, paymentDeadline, error } = translations || {};

    if (isLoading) return <Box textAlign="center" {...props}>
        <CircularProgress />
    </Box>;

    const currencySym = currency && getCurrencySymbol(currency);

    return payment ? <Box {...props}>
        <Stack spacing={2} direction="row">
            <MultibancoIcon style={{ fontSize: "80px" }} />
            <FieldList spacing={0.5}>
                <Field title={entity || 'Entity'} value={payment.entity} />
                <Field title={reference || 'Reference'} value={payment.reference} />
                <Field title={value || 'Value'} value={`${payment.amount}${(currencySym ? ` ${currencySym}` : '')}`} />
            </FieldList>
        </Stack>
        <Box>
            <Typography variant="subtitle1" display="inline">
                {paymentDeadline || 'Payment deadline'}:
            </Typography>
            <Typography variant="body1" ml={1} display="inline">
                {format(new Date(payment.expires), "d MMMM yyyy", { locale: locales[locale] })}
            </Typography>
        </Box>
    </Box> :
    <Box {...props} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', color: theme => theme.palette.error.dark, ...(props.sx || {}) }} >
        <Icon icon="mdi:alert" height={100} />
        <Typography variant="h5" mt={1} >
            {'Error'}
        </Typography>
    </Box>
}