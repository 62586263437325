import AD_flag from '../../assets/flags/AD.svg';
import AE_flag from '../../assets/flags/AE.svg';
import AF_flag from '../../assets/flags/AF.svg';
import AG_flag from '../../assets/flags/AG.svg';
import AI_flag from '../../assets/flags/AI.svg';
import AL_flag from '../../assets/flags/AL.svg';
import AM_flag from '../../assets/flags/AM.svg';
import AO_flag from '../../assets/flags/AO.svg';
import AR_flag from '../../assets/flags/AR.svg';
import AT_flag from '../../assets/flags/AT.svg';
import AU_flag from '../../assets/flags/AU.svg';
import AX_flag from '../../assets/flags/AX.svg';
import AZ_flag from '../../assets/flags/AZ.svg';
import BA_flag from '../../assets/flags/BA.svg';
import BB_flag from '../../assets/flags/BB.svg';
import BD_flag from '../../assets/flags/BD.svg';
import BE_flag from '../../assets/flags/BE.svg';
import BF_flag from '../../assets/flags/BF.svg';
import BG_flag from '../../assets/flags/BG.svg';
import BH_flag from '../../assets/flags/BH.svg';
import BI_flag from '../../assets/flags/BI.svg';
import BJ_flag from '../../assets/flags/BJ.svg';
import BM_flag from '../../assets/flags/BM.svg';
import BN_flag from '../../assets/flags/BN.svg';
import BO_flag from '../../assets/flags/BO.svg';
import BR_flag from '../../assets/flags/BR.svg';
import BS_flag from '../../assets/flags/BS.svg';
import BT_flag from '../../assets/flags/BT.svg';
import BW_flag from '../../assets/flags/BW.svg';
import BY_flag from '../../assets/flags/BY.svg';
import BZ_flag from '../../assets/flags/BZ.svg';
import CA_flag from '../../assets/flags/CA.svg';
import CAF_flag from '../../assets/flags/CAF.svg';
import CAS_flag from '../../assets/flags/CAS.svg';
import CD_flag from '../../assets/flags/CD.svg';
import CEU_flag from '../../assets/flags/CEU.svg';
import CF_flag from '../../assets/flags/CF.svg';
import CG_flag from '../../assets/flags/CG.svg';
import CH_flag from '../../assets/flags/CH.svg';
import CI_flag from '../../assets/flags/CI.svg';
import CL_flag from '../../assets/flags/CL.svg';
import CM_flag from '../../assets/flags/CM.svg';
import CN_flag from '../../assets/flags/CN.svg';
import CNA_flag from '../../assets/flags/CNA.svg';
import CO_flag from '../../assets/flags/CO.svg';
import COC_flag from '../../assets/flags/COC.svg';
import CR_flag from '../../assets/flags/CR.svg';
import CSA_flag from '../../assets/flags/CSA.svg';
import CU_flag from '../../assets/flags/CU.svg';
import CV_flag from '../../assets/flags/CV.svg';
import CY_flag from '../../assets/flags/CY.svg';
import CZ_flag from '../../assets/flags/CZ.svg';
import DE_flag from '../../assets/flags/DE.svg';
import DJ_flag from '../../assets/flags/DJ.svg';
import DK_flag from '../../assets/flags/DK.svg';
import DM_flag from '../../assets/flags/DM.svg';
import DO_flag from '../../assets/flags/DO.svg';
import DZ_flag from '../../assets/flags/DZ.svg';
import EC_flag from '../../assets/flags/EC.svg';
import EE_flag from '../../assets/flags/EE.svg';
import EG_flag from '../../assets/flags/EG.svg';
import EMPTY_flag from '../../assets/flags/EMPTY.svg';
import ER_flag from '../../assets/flags/ER.svg';
import ES_flag from '../../assets/flags/ES.svg';
import ET_flag from '../../assets/flags/ET.svg';
import EU_flag from '../../assets/flags/EU.svg';
import FI_flag from '../../assets/flags/FI.svg';
import FJ_flag from '../../assets/flags/FJ.svg';
import FM_flag from '../../assets/flags/FM.svg';
import FR_flag from '../../assets/flags/FR.svg';
import GA_flag from '../../assets/flags/GA.svg';
import GB_flag from '../../assets/flags/GB.svg';
import GD_flag from '../../assets/flags/GD.svg';
import GE_flag from '../../assets/flags/GE.svg';
import GH_flag from '../../assets/flags/GH.svg';
import GM_flag from '../../assets/flags/GM.svg';
import GN_flag from '../../assets/flags/GN.svg';
import GQ_flag from '../../assets/flags/GQ.svg';
import GR_flag from '../../assets/flags/GR.svg';
import GT_flag from '../../assets/flags/GT.svg';
import GW_flag from '../../assets/flags/GW.svg';
import GY_flag from '../../assets/flags/GY.svg';
import HK_flag from '../../assets/flags/HK.svg';
import HN_flag from '../../assets/flags/HN.svg';
import HR_flag from '../../assets/flags/HR.svg';
import HT_flag from '../../assets/flags/HT.svg';
import HU_flag from '../../assets/flags/HU.svg';
import ID_flag from '../../assets/flags/ID.svg';
import IE_flag from '../../assets/flags/IE.svg';
import IL_flag from '../../assets/flags/IL.svg';
import IN_flag from '../../assets/flags/IN.svg';
import IQ_flag from '../../assets/flags/IQ.svg';
import IR_flag from '../../assets/flags/IR.svg';
import IS_flag from '../../assets/flags/IS.svg';
import IT_flag from '../../assets/flags/IT.svg';
import JM_flag from '../../assets/flags/JM.svg';
import JO_flag from '../../assets/flags/JO.svg';
import JP_flag from '../../assets/flags/JP.svg';
import KE_flag from '../../assets/flags/KE.svg';
import KG_flag from '../../assets/flags/KG.svg';
import KH_flag from '../../assets/flags/KH.svg';
import KM_flag from '../../assets/flags/KM.svg';
import KN_flag from '../../assets/flags/KN.svg';
import KP_flag from '../../assets/flags/KP.svg';
import KR_flag from '../../assets/flags/KR.svg';
import KW_flag from '../../assets/flags/KW.svg';
import KY_flag from '../../assets/flags/KY.svg';
import KZ_flag from '../../assets/flags/KZ.svg';
import LA_flag from '../../assets/flags/LA.svg';
import LB_flag from '../../assets/flags/LB.svg';
import LC_flag from '../../assets/flags/LC.svg';
import LI_flag from '../../assets/flags/LI.svg';
import LK_flag from '../../assets/flags/LK.svg';
import LR_flag from '../../assets/flags/LR.svg';
import LS_flag from '../../assets/flags/LS.svg';
import LT_flag from '../../assets/flags/LT.svg';
import LU_flag from '../../assets/flags/LU.svg';
import LV_flag from '../../assets/flags/LV.svg';
import LY_flag from '../../assets/flags/LY.svg';
import MA_flag from '../../assets/flags/MA.svg';
import MC_flag from '../../assets/flags/MC.svg';
import MD_flag from '../../assets/flags/MD.svg';
import ME_flag from '../../assets/flags/ME.svg';
import MG_flag from '../../assets/flags/MG.svg';
import MK_flag from '../../assets/flags/MK.svg';
import ML_flag from '../../assets/flags/ML.svg';
import MM_flag from '../../assets/flags/MM.svg';
import MN_flag from '../../assets/flags/MN.svg';
import MO_flag from '../../assets/flags/MO.svg';
import MR_flag from '../../assets/flags/MR.svg';
import MS_flag from '../../assets/flags/MS.svg';
import MT_flag from '../../assets/flags/MT.svg';
import MU_flag from '../../assets/flags/MU.svg';
import MV_flag from '../../assets/flags/MV.svg';
import MW_flag from '../../assets/flags/MW.svg';
import MX_flag from '../../assets/flags/MX.svg';
import MY_flag from '../../assets/flags/MY.svg';
import MZ_flag from '../../assets/flags/MZ.svg';
import NA_flag from '../../assets/flags/NA.svg';
import NE_flag from '../../assets/flags/NE.svg';
import NG_flag from '../../assets/flags/NG.svg';
import NI_flag from '../../assets/flags/NI.svg';
import NL_flag from '../../assets/flags/NL.svg';
import NO_flag from '../../assets/flags/NO.svg';
import NP_flag from '../../assets/flags/NP.svg';
import NZ_flag from '../../assets/flags/NZ.svg';
import OM_flag from '../../assets/flags/OM.svg';
import PA_flag from '../../assets/flags/PA.svg';
import PE_flag from '../../assets/flags/PE.svg';
import PG_flag from '../../assets/flags/PG.svg';
import PH_flag from '../../assets/flags/PH.svg';
import PK_flag from '../../assets/flags/PK.svg';
import PL_flag from '../../assets/flags/PL.svg';
import PR_flag from '../../assets/flags/PR.svg';
import PT_flag from '../../assets/flags/PT.svg';
import PW_flag from '../../assets/flags/PW.svg';
import PY_flag from '../../assets/flags/PY.svg';
import QA_flag from '../../assets/flags/QA.svg';
import RO_flag from '../../assets/flags/RO.svg';
import RS_flag from '../../assets/flags/RS.svg';
import RU_flag from '../../assets/flags/RU.svg';
import RW_flag from '../../assets/flags/RW.svg';
import SA_flag from '../../assets/flags/SA.svg';
import SB_flag from '../../assets/flags/SB.svg';
import SC_flag from '../../assets/flags/SC.svg';
import SD_flag from '../../assets/flags/SD.svg';
import SE_flag from '../../assets/flags/SE.svg';
import SG_flag from '../../assets/flags/SG.svg';
import SI_flag from '../../assets/flags/SI.svg';
import SK_flag from '../../assets/flags/SK.svg';
import SL_flag from '../../assets/flags/SL.svg';
import SM_flag from '../../assets/flags/SM.svg';
import SN_flag from '../../assets/flags/SN.svg';
import SO_flag from '../../assets/flags/SO.svg';
import SR_flag from '../../assets/flags/SR.svg';
import ST_flag from '../../assets/flags/ST.svg';
import SV_flag from '../../assets/flags/SV.svg';
import SY_flag from '../../assets/flags/SY.svg';
import SZ_flag from '../../assets/flags/SZ.svg';
import TC_flag from '../../assets/flags/TC.svg';
import TD_flag from '../../assets/flags/TD.svg';
import TG_flag from '../../assets/flags/TG.svg';
import TH_flag from '../../assets/flags/TH.svg';
import TJ_flag from '../../assets/flags/TJ.svg';
import TL_flag from '../../assets/flags/TL.svg';
import TM_flag from '../../assets/flags/TM.svg';
import TN_flag from '../../assets/flags/TN.svg';
import TO_flag from '../../assets/flags/TO.svg';
import TR_flag from '../../assets/flags/TR.svg';
import TT_flag from '../../assets/flags/TT.svg';
import TW_flag from '../../assets/flags/TW.svg';
import TZ_flag from '../../assets/flags/TZ.svg';
import UA_flag from '../../assets/flags/UA.svg';
import UG_flag from '../../assets/flags/UG.svg';
import US_flag from '../../assets/flags/US.svg';
import UY_flag from '../../assets/flags/UY.svg';
import UZ_flag from '../../assets/flags/UZ.svg';
import VC_flag from '../../assets/flags/VC.svg';
import VE_flag from '../../assets/flags/VE.svg';
import VG_flag from '../../assets/flags/VG.svg';
import VN_flag from '../../assets/flags/VN.svg';
import WS_flag from '../../assets/flags/WS.svg';
import WW_flag from '../../assets/flags/WW.svg';
import YE_flag from '../../assets/flags/YE.svg';
import ZA_flag from '../../assets/flags/ZA.svg';
import ZW_flag from '../../assets/flags/ZW.svg';

const CountryFlags = {
    AD: AD_flag,
    AE: AE_flag,
    AF: AF_flag,
    AG: AG_flag,
    AI: AI_flag,
    AL: AL_flag,
    AM: AM_flag,
    AO: AO_flag,
    AR: AR_flag,
    AT: AT_flag,
    AU: AU_flag,
    AX: AX_flag,
    AZ: AZ_flag,
    BA: BA_flag,
    BB: BB_flag,
    BD: BD_flag,
    BE: BE_flag,
    BF: BF_flag,
    BG: BG_flag,
    BH: BH_flag,
    BI: BI_flag,
    BJ: BJ_flag,
    BM: BM_flag,
    BN: BN_flag,
    BO: BO_flag,
    BR: BR_flag,
    BS: BS_flag,
    BT: BT_flag,
    BW: BW_flag,
    BY: BY_flag,
    BZ: BZ_flag,
    CA: CA_flag,
    CAF: CAF_flag,
    CAS: CAS_flag,
    CD: CD_flag,
    CEU: CEU_flag,
    CF: CF_flag,
    CG: CG_flag,
    CH: CH_flag,
    CI: CI_flag,
    CL: CL_flag,
    CM: CM_flag,
    CN: CN_flag,
    CNA: CNA_flag,
    CO: CO_flag,
    COC: COC_flag,
    CR: CR_flag,
    CSA: CSA_flag,
    CU: CU_flag,
    CV: CV_flag,
    CY: CY_flag,
    CZ: CZ_flag,
    DE: DE_flag,
    DJ: DJ_flag,
    DK: DK_flag,
    DM: DM_flag,
    DO: DO_flag,
    DZ: DZ_flag,
    EC: EC_flag,
    EE: EE_flag,
    EG: EG_flag,
    EMPTY: EMPTY_flag,
    ER: ER_flag,
    ES: ES_flag,
    ET: ET_flag,
    EU: EU_flag,
    FI: FI_flag,
    FJ: FJ_flag,
    FM: FM_flag,
    FR: FR_flag,
    GA: GA_flag,
    GB: GB_flag,
    GD: GD_flag,
    GE: GE_flag,
    GH: GH_flag,
    GM: GM_flag,
    GN: GN_flag,
    GQ: GQ_flag,
    GR: GR_flag,
    GT: GT_flag,
    GW: GW_flag,
    GY: GY_flag,
    HK: HK_flag,
    HN: HN_flag,
    HR: HR_flag,
    HT: HT_flag,
    HU: HU_flag,
    ID: ID_flag,
    IE: IE_flag,
    IL: IL_flag,
    IN: IN_flag,
    IQ: IQ_flag,
    IR: IR_flag,
    IS: IS_flag,
    IT: IT_flag,
    JM: JM_flag,
    JO: JO_flag,
    JP: JP_flag,
    KE: KE_flag,
    KG: KG_flag,
    KH: KH_flag,
    KM: KM_flag,
    KN: KN_flag,
    KP: KP_flag,
    KR: KR_flag,
    KW: KW_flag,
    KY: KY_flag,
    KZ: KZ_flag,
    LA: LA_flag,
    LB: LB_flag,
    LC: LC_flag,
    LI: LI_flag,
    LK: LK_flag,
    LR: LR_flag,
    LS: LS_flag,
    LT: LT_flag,
    LU: LU_flag,
    LV: LV_flag,
    LY: LY_flag,
    MA: MA_flag,
    MC: MC_flag,
    MD: MD_flag,
    ME: ME_flag,
    MG: MG_flag,
    MK: MK_flag,
    ML: ML_flag,
    MM: MM_flag,
    MN: MN_flag,
    MO: MO_flag,
    MR: MR_flag,
    MS: MS_flag,
    MT: MT_flag,
    MU: MU_flag,
    MV: MV_flag,
    MW: MW_flag,
    MX: MX_flag,
    MY: MY_flag,
    MZ: MZ_flag,
    NA: NA_flag,
    NE: NE_flag,
    NG: NG_flag,
    NI: NI_flag,
    NL: NL_flag,
    NO: NO_flag,
    NP: NP_flag,
    NZ: NZ_flag,
    OM: OM_flag,
    PA: PA_flag,
    PE: PE_flag,
    PG: PG_flag,
    PH: PH_flag,
    PK: PK_flag,
    PL: PL_flag,
    PR: PR_flag,
    PT: PT_flag,
    PW: PW_flag,
    PY: PY_flag,
    QA: QA_flag,
    RO: RO_flag,
    RS: RS_flag,
    RU: RU_flag,
    RW: RW_flag,
    SA: SA_flag,
    SB: SB_flag,
    SC: SC_flag,
    SD: SD_flag,
    SE: SE_flag,
    SG: SG_flag,
    SI: SI_flag,
    SK: SK_flag,
    SL: SL_flag,
    SM: SM_flag,
    SN: SN_flag,
    SO: SO_flag,
    SR: SR_flag,
    ST: ST_flag,
    SV: SV_flag,
    SY: SY_flag,
    SZ: SZ_flag,
    TC: TC_flag,
    TD: TD_flag,
    TG: TG_flag,
    TH: TH_flag,
    TJ: TJ_flag,
    TL: TL_flag,
    TM: TM_flag,
    TN: TN_flag,
    TO: TO_flag,
    TR: TR_flag,
    TT: TT_flag,
    TW: TW_flag,
    TZ: TZ_flag,
    UA: UA_flag,
    UG: UG_flag,
    US: US_flag,
    UY: UY_flag,
    UZ: UZ_flag,
    VC: VC_flag,
    VE: VE_flag,
    VG: VG_flag,
    VN: VN_flag,
    WS: WS_flag,
    WW: WW_flag,
    YE: YE_flag,
    ZA: ZA_flag,
    ZW: ZW_flag
};


export function Flag({ country, ...props }) {
  const flagName = country || "EMPTY";
  return (
    <img
      alt={country}
      src={CountryFlags[flagName]}
      {...props} />
  )
}

export default CountryFlags;