import { Icon } from '@iconify/react';
import { Box, Button, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import CountrySelect from '@nvapps/common/components/ui/CountrySelect';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import PhoneInput, { isValidPhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import { isEmptyField } from '@nvapps/common/utils';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { updateBillingInformation } from '../../../api/profile';
import { useSaveApi } from '../../../hooks';
import { useProfileLanguage, useUserBillingInfo } from '../../../store/user';
import ConfirmPopover from '../../Ui/ConfirmPopover';

function Form({ billingInfo, errors, onChange }) {

    const { t } = useTranslation();
    const language = useProfileLanguage();

    const handleChangeField = (e) => {
        onChange && onChange({
            ...billingInfo,
            [e.target.name]: e.target.value
        });
    }

    const handlePhoneNumberChange = ({ value }) => {
        onChange && onChange({
            ...billingInfo,
            phoneNumber: value
        });
    };

    function getMessage(key) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : '';
    }

    return <React.Fragment>
        <Grid item xs={12}>
            <TextField
                required
                name="fullName"
                label={t('fullName')}
                fullWidth
                value={billingInfo.fullName}
                error={hasError(errors, "fullName")}
                helperText={getMessage("fullName")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12}>
            <TextField
                required
                name="address"
                label={t('address')}
                fullWidth
                value={billingInfo.address}
                error={hasError(errors, "address")}
                helperText={getMessage("address")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12} sm={4}>
            <TextField
                name="postalCode"
                fullWidth
                label={t('postalCode')}
                value={billingInfo.postalCode}
                error={hasError(errors, "postalCode")}
                helperText={getMessage("postalCode")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12} sm={8}>
            <TextField
                required
                name="city"
                fullWidth
                label={t('city')}
                value={billingInfo.city}
                error={hasError(errors, "city")}
                helperText={getMessage("city")}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12}>
            <TextField
                name="vat"
                fullWidth
                label={t('vat')}
                value={billingInfo.vat}
                onChange={handleChangeField} />
        </Grid>
        <Grid item xs={12}>
            <CountrySelect
                required
                name="country"
                language={language}
                label={t('country')}
                textFieldProps={{ fullWidth: true }}
                value={billingInfo.country || 'PT'}
                error={hasError(errors, "country")}
                helperText={getMessage("country")}
                onChange={(ev, opt) => opt && onChange({...billingInfo, country: opt.value })}
            />
        </Grid>
        <Grid item xs>
            <PhoneInput
                required
                name="phoneNumber"
                fullWidth
                label={t('phoneNumber')}
                value={billingInfo.phoneNumber || ''}
                error={hasError(errors, "phoneNumber")}
                helperText={getMessage("phoneNumber")}
                onChange={handlePhoneNumberChange}
            />
        </Grid>
    </React.Fragment>
}

const getDefaultBillingInfo = () =>
({
    fullName: '',
    company: '',
    email: '',
    phoneNumber: '',
    postalCode: '',
    address: '',
    city: '',
    country: 'PT',
    vat : ''
})

export default function BillingInformationStep({ billingInfo, onNext, onBack }) {

    const [billingInfoSaved, isFetching] = useUserBillingInfo();
    const [formData, setFormData] = useState(billingInfo || billingInfoSaved || getDefaultBillingInfo());
    const [confirmOpen, setConfirmOpen] = useState(false);
    const ref = useRef(null);
    const [saveApi, isSaving] = useSaveApi();

    useEffect(() => setFormData(billingInfo || billingInfoSaved || getDefaultBillingInfo()), [billingInfoSaved]);

    const errors = useMemo(() => {
        const err = [];

        if (formData.fullName.length === 0) err.push({ id: "fullName", message: "requiredField" });
        if (formData.address.length === 0) err.push({ id: "address", message: "requiredField" });
        //if (formData.postalCode.length === 0) err.push({ id: "postalCode", message: "requiredField" });
        if (formData.city.length === 0) err.push({ id: "city", message: "requiredField" });
        if (!isValidPhoneNumber(formData.phoneNumber)) err.push({ id: "phoneNumber", message: "invalidPhoneNumber" });

        return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
    }, [formData]);

    const handleChange = formData => setFormData(formData);
    const handleClickNext = () =>
    {
        if (JSON.stringify(billingInfoSaved) !== JSON.stringify(formData)) setConfirmOpen(true);
        else onNext && onNext(formData);
    }

    const handleConfirmation = async action =>
    {
        if (action === true)
        {
            const res = await saveApi(updateBillingInformation(formData));
            if (res.success !== true) return;
        }
        onNext && onNext(formData);
    }

    return <Box component="form" autoComplete="off" >
        {(isFetching || isSaving) && (<OverlapProgress progressProps={{ size: 75 }} />)}
        <Grid container spacing={3}>
            <Form
                billingInfo={formData}
                errors={errors}
                onChange={handleChange} />
            <Grid item xs={12} >
                <Box display={"flex"}>
                    <Button onClick={onBack}>
                        <Trans>back</Trans>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ ml : 1 }}
                        ref={ref}
                        disabled={Boolean(errors)}
                        onClick={handleClickNext}>
                        <Trans>next</Trans>
                    </Button>
                    {!isEmptyField(billingInfoSaved ? billingInfoSaved.fullName : null) &&
                    <Tooltip title={<Trans>getBillingInfo</Trans>}>
                        <IconButton color="primary" sx={{ ml : 'auto' }} onClick={() => setFormData(billingInfoSaved)}>
                            <Icon icon="eva:refresh-fill" />
                        </IconButton>
                    </Tooltip>}
                </Box>
                <ConfirmPopover
                    text={<Trans>updateBillingInfoQuestion</Trans>}
                    open={confirmOpen}
                    anchorEl={ref.current}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    onClose={() => setConfirmOpen(false)}
                    onResolve={handleConfirmation} />
            </Grid>
        </Grid>
    </Box>
}