const cancelActions = {};

const debounce = () => next => action => {
    if (cancelActions.hasOwnProperty(action.type)) {
        clearTimeout(cancelActions[action.type]);
        delete cancelActions[action.type];
    }

    if (action.meta && action.meta.debounce) {
        cancelActions[action.type] = setTimeout(() => {
            next(action);
            delete cancelActions[action.type];
        }, action.meta.debounce)
    } else {
        next(action);
    }
}

export default debounce;
