import { Box, Button, Link, TextField, Typography } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import { getLocalTimeZone } from '@nvapps/common/utils/dateUtils';
import FormPage from '@nvtracker/common/components/FormPage';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { appIdentifier } from '../../package.json';
import { usersPagesBaseUrl } from '../api/utils';
import { useAuthenticationActions, useAuthenticationState } from '../store/authentication';

// function LoginPopup()
// {
//     const [username, setUsername] = useState("");
//     const [password, setPassword] = useState("");
//     const { loginFailed, loginStarted } = useAuthenticationState();
//     const { login } = useAuthenticationActions();
//     const { t } = useTranslation();
//     const currentUrlEncoded = encodeURIComponent(window.location.toString());

//     const handleLoginClick = (e) => {
//         e.preventDefault();
//         const timeZone = getLocalTimeZone();

//         const host = `${usersPagesBaseUrl}/login?returnPage=${currentUrlEncoded}&appToken=${appIdentifier}`;
//         var left = (window.screen.width - 600) / 2;
//         var top = (window.screen.height - 600) / 2;

//         let newWin = window.open(host, 'center window', `width=600,height=600,left=${left},top=${top}popup=yes`);
//         console.log("🚀 ~ file: Login.js ~ line 30 ~ handleLoginClick ~ newWin", newWin)

//         newWin.opener.postMessage("hello there!", 'http://localhost');

//         newWin.addEventListener("message", (event) => {
//             console.log("🚀 ~ file: Login.js ~ line 33 ~ newWin.addEventListener ~ event", event)

//             if (event.origin !== "http://example.com")
//                 return;

//             // event.source is popup
//             // event.data is "hi there yourself!  the secret response is: rheeeeet!"
//         }, false);
//     }

//     return (
//         <FormPage>
//             <Box component="form" p={3} sx={{ width: '100%' }}>
//                 <StatusAlert
//                     sx={{ marginTop: 2 }}
//                     visible={loginFailed}
//                     variant="error"
//                     message={t('errorLoginFailed')}>
//                 </StatusAlert>
//                 <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={0}>
//                      <Button
//                         href={`${usersPagesBaseUrl}/register?returnPage=${currentUrlEncoded}`}
//                         color="primary"
//                         disabled={loginStarted}>
//                         Sign Up
//                     </Button>
//                     <ButtonWithProgress
//                         type="submit"
//                         variant="contained"
//                         color="primary"
//                         loading={loginStarted}
//                         onClick={handleLoginClick}>
//                         {t('loginWithNovatronica')}
//                     </ButtonWithProgress>
//                 </Box>
//                 <Typography style={{textAlign: "right"}} mt={2}>
//                     <Link
//                         href={`${usersPagesBaseUrl}/forgot-password?returnPage=${currentUrlEncoded}`}>
//                         Forgot the password?
//                     </Link>
//                 </Typography>
//             </Box>
//         </FormPage>
//     );
// }

function Login () {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { loginFailed, loginStarted } = useAuthenticationState();
    const { login } = useAuthenticationActions();
    const currentUrlEncoded = encodeURIComponent(window.location.toString());
    const appToken = encodeURIComponent(appIdentifier);

    const handleLoginClick = (e) => {
        e.preventDefault();
        const timeZone = getLocalTimeZone();
        login({ username, password, timeZone });
    }

    return (
        <FormPage>
            <Box component="form" p={3} sx={{ width: '100%' }}>
                <TextField
                    htmlFor="username"
                    required
                    fullWidth
                    sx={{ mb: 2 }}
                    variant="standard"
                    label={<Trans>email</Trans>}
                    name="username"
                    id="username"
                    autoComplete="username"
                    autoFocus
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField
                    htmlFor="password"
                    required
                    fullWidth
                    variant="standard"
                    label={<Trans>password</Trans>}
                    name="password"
                    type="password"
                    id="password"
                    sx={{ mb: 2 }}
                    autoComplete="current-password"
                    onChange={e => setPassword(e.target.value)}
                />
                <Typography style={{textAlign: "right"}}>
                    <Link
                        href={`${usersPagesBaseUrl}/forgot-password?returnPage=${currentUrlEncoded}`}>
                        <Trans>forgotPassword</Trans>
                    </Link>
                </Typography>
                <StatusAlert
                    sx={{ marginTop: 2 }}
                    visible={loginFailed}
                    variant="error"
                    message={<Trans>errorLoginFailed</Trans>}>
                </StatusAlert>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2}>
                     <Button
                        href={`${usersPagesBaseUrl}/register?returnPage=${currentUrlEncoded}&appToken=${appToken}`}
                        color="primary"
                        disabled={loginStarted}>
                        <Trans>signUp</Trans>
                    </Button>
                    <ButtonWithProgress
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={username.length === 0 || password.length === 0}
                        loading={loginStarted}
                        onClick={handleLoginClick}>
                        {<Trans>login</Trans>}
                    </ButtonWithProgress>
                </Box>
            </Box>
        </FormPage>
    );
}

export default Login;