import React from "react";
import Grid from "@mui/material/Grid";
import { StripeCardNumberField, StripeCardExpiryField, StripeCardCVCField } from "./StripeInputFields";

export default function StripeCardDetails({ onCompleteChange, translations = {} }) {
    const [completeFields, setCompleteFields] = React.useState({
        cardNumber: false,
        cardExpiry: false,
        cardCVC: false
    });

    const handleCompleteChange = (propName) => ({ complete }) => {
        const newCompleteFields = {
            ...completeFields,
            [propName]: complete
        };
        setCompleteFields(newCompleteFields);
        const isComplete = Object.values(newCompleteFields).reduce((acc, f) => acc && f, true);
        onCompleteChange && onCompleteChange(isComplete);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} id={"card-number"}>
                <StripeCardNumberField label={translations.cardNumber} onChange={handleCompleteChange("cardNumber")} />
                <StripeCardExpiryField label={translations.expiryDate} onChange={handleCompleteChange("cardExpiry")} />
                <StripeCardCVCField label={translations.CVC} onChange={handleCompleteChange("cardCVC")} />
            </Grid>
        </Grid>
    )
}