import { Box, Chip, MenuItem, TextField } from '@mui/material'
import { getCurrencySymbol } from '@nvapps/common/utils'
import React from 'react'

export default function SelectPlan({ plans, value, label = 'Plan', defaultText = 'Default', onChange, ...props}) {
    return (
        <TextField
            select
            label={label}
            value={value}
            onChange={onChange}
            {...props} >
            {(plans || []).map(e => <MenuItem key={e.id} value={e.id}>
                <Box component={'label'} sx={{mr : 1}}>{`${e.name}`}</Box>
                <Chip color="info" size="small" sx={{ ml : 'auto' }} label={`${e.price} ${getCurrencySymbol(e.currency)}`} />
            </MenuItem>)}
        </TextField>
    )
}


//   <TextField
//     required
//     select
//     label={t('plan')}
//     value={planId}
//     name="plan"
//     error={planId === ''}
//     fullWidth
//     InputProps={{
//         endAdornment: <InputAdornment position="start" sx={{ mr : 2}}>
//             {plan ? <Chip color="info" label={`${plan.price} ${getCurrencySymbol(plan.currency)}`} /> : ''}
//         </InputAdornment>,
//     }}
//     onChange={ev => setPlan(ev.target.value)}
// >
//     {(plans || []).map(e => <MenuItem key={e.id} value={e.id}>
//         {e.name}
//     </MenuItem>)}
// </TextField>