import React from "react";
import TextField from "@mui/material/TextField";
import StripeInput from "./StripeInput";

export default function StripeElementWrapper(props) {
    const {
        InputLabelProps,
        InputProps,
        fullWidth = true,
        label = "",
        stripeComponent,
        endAdornment,
        margin = "normal",
        onChange,
        ...other
    } = props;

    const [error, setError] = React.useState(null);

    const handleOnChange = (eventProps) => {
        const { error = { message: null } } = eventProps;
        setError(error.message);
        onChange && onChange(eventProps);
    }

    return (
        <TextField
            fullWidth={fullWidth}
            margin={margin}
            label={label}
            helperText={error}
            error={Boolean(error)}
            InputLabelProps={{ ...InputLabelProps, shrink: true }}
            InputProps={{
                ...InputProps,
                inputProps: {
                    component: stripeComponent
                },
                inputComponent: StripeInput
            }}
            onChange={handleOnChange}
            {...other} />
    );
}
