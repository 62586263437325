import React from "react";
import MultibancoPaymentModal from "./MultibancoPaymentModal";
import CardPaymentModal from "./CardPaymentModal";

export default function PaymentModalBase({ paymentType, ...props }) {
    if (!props.open) return false;

    switch (paymentType) {
        case "Multibanco":
            return <MultibancoPaymentModal {...props} />;
        case "Card":
            return <CardPaymentModal {...props} />;
        default:
            return false;
    }
}

