import React from "react";
import { ReactComponent as CarIconComp } from "../../assets/dark/car.svg";
import { ReactComponent as CarAlertIconComp } from "../../assets/dark/car-alert.svg";
import { ReactComponent as MapIconComp } from "../../assets/dark/map.svg";
import { ReactComponent as MultibancoIconComp } from "../../assets/dark/multibanco.svg";

const baseIconStyle = {
    height: "1em", 
    width: "1em", 
    fontSize: "1.5rem", 
    fill: "currentcolor"
};

function createIconComponent(BaseComp, { style, ...props }) {
    return <BaseComp style={{...baseIconStyle, ...style}} {...props} />
}

export function CarIcon(props) {
    return createIconComponent(CarIconComp, props);
}

export function CarAlertIcon(props) {
    return createIconComponent(CarAlertIconComp, props);
}

export function MapIcon(props) {
    return createIconComponent(MapIconComp, props);
}

export function MultibancoIcon(props) {
    return createIconComponent(MultibancoIconComp, props);
}

