import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({ open, title, children, confirmText = 'Confirm', cancelText = 'Cancel', onConfirm, onCancel }) {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    {cancelText}
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

/* For use in app/styles/color-palette.scss */

export const mcgpalette150 = '#e9ebec';
export const mcgpalette1100 = '#c7ced1';
export const mcgpalette1200 = '#a2adb2';
export const mcgpalette1300 = '#7d8c93';
export const mcgpalette1400 = '#61737b';
export const mcgpalette1500 = '#455a64';
export const mcgpalette1600 = '#3e525c';
export const mcgpalette1700 = '#364852';
export const mcgpalette1800 = '#2e3f48';
export const mcgpalette1900 = '#1f2e36';
export const mcgpalette1A100 = '#7dcdff';
export const mcgpalette1A200 = '#4abaff';
export const mcgpalette1A400 = '#17a7ff';
export const mcgpalette1A700 = '#009cfc';
