import { useFetchV2 } from '@nvapps/common/hooks';
import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlansByGsmNumber, getPlansForRenewal, getPlansUrl } from '../api/plans';

const initialState = {
    isFetching: false,
    fetched: false,
    error: null,
    byId: {},
    allIds: []
};

const plans = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        fetchStart(state) {
            state.isFetching = true;
        },
        fetchFailed(state, action) {
            state.isFetching = false;
            state.error = action.payload;
        },
        fetchSuccess(state, action) {
            state.isFetching = false;
            state.fetched = true;
            state.allIds = action.payload.allIds
            state.byId = action.payload.byId
        }
    }
});

export function fetchPlans() {

    const actions = plans.actions;

    return async (dispatch, getState) => {

        const state = getState();
        if (state.plans.isFetching || state.plans.fetched) return;

        dispatch(
        {
            type: actions.fetchStart.type,
            meta:
            {
                debounce: 200,
                apiRequest:
                {
                    method: "GET",
                    url: getPlansUrl,
                    successActionType: actions.fetchSuccess.type,
                    errorActionType : actions.fetchFailed.type,
                    normalizeBy : 'id'
                },
                cancelApiRequest:
                {
                    startedBy: actions.fetchStart.type
                }
            }
        });
    }
}

export function usePlans() {

    const { allIds, byId, isFetching } = useSelector(state => state.plans);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPlans());
    }, [dispatch]);

    return [allIds.map(e=> byId[e]), isFetching];
}

export function usePlansById() {

    const { byId } = useSelector(state => state.plans);
    const [, isFetching] = usePlans();

    return [byId, isFetching];
}

export function usePlanById(id)
{
    const [data, isFetching] = usePlansById();
    return [(data || {})[id], isFetching];
}

export function usePlansOfGsmNumber(gsmNumber) {

    const [data, isLoading] = useFetchV2(gsmNumber ? () => getPlansByGsmNumber(gsmNumber) : null, [gsmNumber]);
    return useMemo(() => {
        return [(data || []).sort((a , b) => {
            if(a.months > b.months) return 1;
            if(a.months < b.months) return -1;
            return 0;
        }), isLoading];
    }, [data, isLoading])
}

export function usePlansForRenewal() {

    return useFetchV2(getPlansForRenewal);
}

export default plans.reducer;