import React from "react";
import CardPaymentModal from "../../modals/CardPaymentModal";
import MultibancoPaymentInfo from "./MultibancoPaymentInfo";

export default function PaymentInfoBase({ paymentType, ...props })
{
    switch (paymentType) {
        case "Multibanco":
            return <MultibancoPaymentInfo {...props} />;
        case "Card":
            return <CardPaymentModal {...props} />;
        default:
            return false;
    }
}