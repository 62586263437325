import { Box } from '@mui/material';
import React from 'react';
import { HeaderLogoIcon } from './ui/Icons';

export default function Logo() {
    return (
        <Box sx={{ py : 0, textAlign : 'center' }}>
            <HeaderLogoIcon style={{width: "100%", maxWidth: "400px"}} />
        </Box>
    );
}
