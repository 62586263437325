import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Flag } from '../Flags';
import countryData from './country_data.js';

function getCountryOfInputNumber(inputValue) {
  let dialCode = inputValue.replace(/\D/, "").substring(0, 4);
  while (dialCode.length > 0) {
    if (dialCode in countryData.allCountryCodes) return countryData.allCountryCodes[dialCode][0];
    dialCode = dialCode.substring(0, dialCode.length - 1);
  }
  return null;
}

export function isValidPhoneNumber(phoneNumber) {
  return /^\+\d{5,15}$/.test(phoneNumber) && getCountryOfInputNumber(phoneNumber);
}

function getState(inputValue) {
  return {
    value: inputValue,
    country: getCountryOfInputNumber(inputValue)
  }
}

function CountryFlag({ country, size })
{
    return <Flag country={country} />
}

export function PhoneNumber({ value, defaultValue = '-' })
{
    if (!value) return defaultValue;

    const country = getCountryOfInputNumber(value);

    return <Box display="flex" flexDirection="row" alignItems="center">
        <Flag country={(country || '').toUpperCase()} style={{ marginRight : 5 }} />
        {value}
    </Box>
}

export default function PhoneInput({ value, size, component, onChange, InputProps, ...props }) {
  const Component = component || TextField;
  const [currentState, setCurrentState] = React.useState({
    value: "",
    country: null
  });

  const handleOnChange = (ev) => {
    const inputValue = ev.target.value || "+";
    if (/^\+\d{0,15}$/.test(inputValue)) {
      const newState = getState(inputValue);
      setCurrentState(newState);
      onChange(newState);
    }
  }

  React.useEffect(() => {
    const inputValue = `+${value.replace(/\D/, "")}`;
    setCurrentState(getState(inputValue));
  }, [value]);

  return (
    <Component
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment position="start" style={{width: 28}}>
            <CountryFlag
              size={size}
              country={currentState.country && currentState.country.toUpperCase()} />
          </InputAdornment>
        ),
      }}
      size={size}
      value={currentState.value}
      onChange={handleOnChange} />
  );
}
