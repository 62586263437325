import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { useRealVerticalHeight } from '@nvapps/common/hooks';
import React from 'react';
import { withStyles } from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const mainContentStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    position: "relative"
};

const ResponsiveDialog = withStyles(theme => ({
    [theme.breakpoints.up("md")]: {
        root: {
            marginLeft: 260
        }
    }
}))(Dialog);

export function FullScreenDialogBase({ open, children }) {
    return (
        <ResponsiveDialog fullScreen hideBackdrop open={open} TransitionComponent={Transition}>
            {children}
        </ResponsiveDialog>
    );
}

export default function FullScreenDialog({ open, title, closeButtonProps, actionButton, children }) {
    const realHeight = useRealVerticalHeight();

    return (
        <FullScreenDialogBase open={open} PaperProps={{style:{marginLeft: 260}}}>
            <div style={{ display: "flex", flexDirection: "column", height: `${realHeight}px` }}>
                <AppBar position="sticky">
                    <Toolbar style={{ display: "flex" }}>
                        <IconButton edge="start" color="inherit" aria-label="close" children={<CloseIcon />} {...closeButtonProps} />
                        <Typography variant="h6" style={{ flex: 1, marginLeft: "10px" }}>
                            {title}
                        </Typography>
                        {actionButton}
                    </Toolbar>
                </AppBar>
                <main style={mainContentStyle}>
                    {children}
                </main>
            </div>
        </FullScreenDialogBase>
    )
}