import { Icon } from '@iconify/react';
import {
    Box, DialogActions, DialogContent, DialogTitle, Divider, List, ListItemIcon, ListItemText, MenuItem,
    Stack, Typography
} from "@mui/material";
import ButtonPopover from "@nvapps/common/components/ui/ButtonPopover";
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import ConfirmDialog from "@nvapps/common/components/ui/ConfirmDialog";
import Dialog from "@nvapps/common/components/ui/Dialog";
import { Field, FieldList } from "@nvapps/common/components/ui/Fields";
import Label from '@nvapps/common/components/ui/Label';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import PaymentInfoBase from '@nvapps/common/components/ui/Payments/PaymentInfoBase';
import { getCurrencySymbol } from "@nvapps/common/utils";
import DeviceListItem from "@nvtracker/common/components/DeviceListItem";
import { getPurchaseStatus, PaymentTypes, PurchasesStatusEnum } from '@nvtracker/common/helpers';
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { cancelPurchase, getPaymentDetails } from '../api/nvtracker';
import DateTime from '../components/Ui/DateTime';
import { useCallApi } from "../hooks";
import { usePurchase } from '../store/purchases';
import PaymentModal from './PaymentModal';

function PaymentInfoContent({ purchase }) {
    const { t } = useTranslation();

    const translations =
    {
        mbPayment: t('MBPayment'),
        entity: t('entity'),
        reference: t('reference'),
        value: t('value'),
        paymentDeadline: t('paymentDeadline'),
        cancel: t('cancel'),
        creditDebitCardPayment: t('creditDebitCardPayment'),
        pay: t('pay'),
        cardNumber: t('cardNumber'),
        expiryDate: t('expiryDate')
    }

    // console.log('purchase', purchase)

    return <PaymentInfoBase
        paymentType={purchase.paymentType}
        currency={purchase.currency}
        translations={translations}
        sx={{ minHeight : 120, display : 'flex', flexDirection : 'column', alignItems : 'center', justifyContent : 'center', gap : 1 }}
        fetchPaymentDetails={() => getPaymentDetails(purchase)} />
}


export function PurchaseOptions({ purchase, onClose }) {

    const [payModalVisible, setPayModalVisible] = useState(false);
    const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);
    const { t } = useTranslation();
    const [callApi] = useCallApi();

    const handlePayPurchase = () => {
        setPayModalVisible(true);
    };

    const handleCancelPurchase = () => {
        setConfirmCancelDialogOpen(true);
    };

    const handleConfirmCancelPurchase = async () => {
        setConfirmCancelDialogOpen(false);
        var res = await callApi(cancelPurchase(purchase.id));
        if (res !== false) onClose && onClose();
    };

    return <>
        <DialogActions>
            <ButtonPopover variant="outlined" size="small" position="top" text={t('options')} sx={{ mr: 'auto' }}>
                {purchase.paymentType === 'Card' &&
                    <MenuItem sx={{ color: 'text.secondary' }} onClick={handlePayPurchase}>
                        <ListItemIcon sx={{ mr: 1.5 }}>
                            <Icon icon="bi:wallet-fill" width={14} />
                        </ListItemIcon>
                        <ListItemText primary={t('pay')} primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>}
                <MenuItem sx={{ color: 'text.secondary' }} onClick={handleCancelPurchase} >
                    <ListItemIcon sx={{ mr: 1.5 }}>
                        <Icon icon="fa-solid:times" width={14} />
                    </ListItemIcon>
                    <ListItemText primary={t('cancel')} primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
            </ButtonPopover>
        </DialogActions>
        <ConfirmDialog
            open={confirmCancelDialogOpen}
            title={t('cancelPurchase')}
            confirmText={t('confirm')}
            cancelText={t('cancel')}
            onConfirm={handleConfirmCancelPurchase}
            onCancel={() => setConfirmCancelDialogOpen(false)}>
            {t('cancelPurchaseQuestion')}
        </ConfirmDialog>
        {payModalVisible &&
            <PaymentModal
                open={payModalVisible}
                purchase={purchase}
                onDone={() => setPayModalVisible(false)}
                onCancel={() => setPayModalVisible(false)} />}
    </>
}

function OptionsButton({ purchase, onClose }) {
    if (purchase.status !== PurchasesStatusEnum.PENDING) return '';
    return <PurchaseOptions purchase={purchase} onClose={onClose} />
}

// Caso seja necessário mostrar os dados de compras
// function BillingInfo({ purchase }) {
//     const { t } = useTranslation();

//     if (!purchase.purchaseBillingInformation) return '';
//     const { fullName, address, city, country, phoneNumber, postalCode, vat } = purchase.purchaseBillingInformation;

//     return <Box sx={{ minWidth: 350 }}>
//         <FieldList p={0}>
//             <Field px={0} title={t('name')} value={fullName} />
//             <Field px={0} title={t('address')} value={address} />
//             <Field px={0} title={t('postalCode')} value={postalCode} />
//             <Field px={0} title={t('city')} value={city} />
//             <Field px={0} title={t('vat')} value={vat} />
//             <Field px={0} title={t('country')} value={country} />
//             <Field px={0} title={t('phoneNumber')} value={phoneNumber} />
//         </FieldList>
//     </Box>
// }

function Content({ onClose }) {

    const { t } = useTranslation();
    const { id } = useParams();
    const [purchase, isFetching] = usePurchase(id);
    const purchaseDevices = useMemo(() => ((purchase || {}).purchaseDevices || []).map(d => ({ deviceId: d.deviceId, name: d.deviceName })), [purchase]);
    const currencySym = getCurrencySymbol((purchase || {}).currency);

    return <React.Fragment>
        <DialogTitle>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {t('purchaseDetails')} - {id}
                <Box>
                    <CloseIconButton title={t('close')} onClick={onClose} />
                </Box>
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Box sx={{ minHeight: 320, minWidth: 300 }}>
                {isFetching && <OverlapProgress />}
                {purchase && <Stack
                    minWidth={350}
                    direction="column"
                    divider={<Divider orientation="horizontal" flexItem />}
                    spacing={2}>
                    <FieldList p={0}>
                        <Field px={0} title={t('status')} value={<Label color={getPurchaseStatus(purchase.status).color}>
                            <Typography variant="subtitle2">
                                {t(getPurchaseStatus(purchase.status).text)}
                            </Typography>
                        </Label>} />
                        <Field px={0} title={t('statusDate')} value={<DateTime datetime={purchase.statusDate} format="dd MMMM yyyy HH:mm" />} />
                        <Field px={0} title={t('payment')} value={<Label color='info'>
                            <Typography variant="subtitle2">
                                {t((PaymentTypes[purchase.paymentType] || PaymentTypes.Other).text)}
                            </Typography>
                        </Label>} />
                        <Field px={0} title={t('created')} value={<DateTime datetime={purchase.creationDate} format="dd MMMM yyyy HH:mm" />} />
                    </FieldList>
                    <FieldList p={0}>
                        <Field p={0}
                            title={<Typography variant="span" fontSize="1.3em" fontWeight={600}>{t('total')}</Typography>}
                            value={<Typography variant="span" fontSize="1.3em" fontWeight="normal" color="primary.main">{`${purchase.total} ${currencySym}`}</Typography>} />
                    </FieldList>
                    <Box>
                        <Typography variant="overline" color="text.secondary">
                            {t('affectedDevices')}
                        </Typography>
                        <Box sx={{ maxHeight: 130, overflow: 'auto' }}>
                            <List>
                                {purchaseDevices.length === 0 ?
                                    <ListItemText primary="N/D" /> :
                                    purchaseDevices.map(d => (
                                        <DeviceListItem sx={{ paddingRight: 0, paddingLeft: 0 }} device={d} key={d.deviceId} />
                                    )
                                    )}
                            </List>
                        </Box>
                    </Box>
                    {purchase.status === PurchasesStatusEnum.PENDING && purchase.paymentType === 'Multibanco' && <PaymentInfoContent purchase={purchase} />}

                </Stack>}
            </Box>
        </DialogContent>
        {purchase && <OptionsButton purchase={purchase} onClose={onClose} />}
    </React.Fragment>
}

export default function PurchaseDetailsDialog({ open, onClose }) {

    return (
        <Dialog
            open={open}
            fullWidth={false}
            onClose={onClose}
            maxWidth="xl"
            disableBackdropClick={false}
            disableEscapeKeyDown={false}>
            <Content onClose={onClose} />
        </Dialog>
    );
}