import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as formatDate, formatRelative, formatDistance, isDate } from "date-fns";

import { locales } from '@nvapps/common/utils/dateUtils';

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'pt',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default,

            format: (value, format, lng) => {

                if (isDate(value)) {

                    const locale = locales[lng];

                    if (format === "short")
                        return formatDate(value, "P", { locale });
                    if (format === "long")
                        return formatDate(value, "PPPP", { locale });
                    if (format === "relative")
                        return formatRelative(value, new Date(), { locale });
                    if (format === "ago")
                        return formatDistance(value, new Date(), {
                            locale,
                            addSuffix: true
                        });

                    return formatDate(value, format, { locale });
                }

                return value;
            }
        },
        resources: {
            en: {
                translation: {
                    search: 'Search',
                    selected: '{{count}} selected'
                }
            },
            pt: {
                translation: {
                    search: 'Pesquisar',
                    selected_one: '{{count}} selecionada',
                    selected_other: '{{count}} selecionadas',
                    delete: 'Eliminar',
                    filterList: 'Filtrar lista',
                    yes: 'Sim',
                    no: 'Não',
                    deleteQuestion: 'Deseja eliminar?',
                    name: 'Nome',
                    username: 'Utilizador',
                    country: 'País',
                    edit: 'Editar',
                    informations: 'Informações',
                    save: 'Guardar',
                    requiredField: 'Campo obrigatório',
                    newsletter: 'Boletim informativo',
                    email: 'Email',
                    profile: 'Perfil',
                    language: 'Idioma',
                    registerDevice: 'Registar equipamento',
                    regularized: 'Regularizado',
                    renewDevice: 'Renovar equipamento',
                    expired: 'Expirou',
                    expiring: 'A expirar',
                    expires: 'Expira',
                    allowedImagemFiles: 'Allowed *.jpeg, *.jpg, *.png, *.gif max size of 2 MB',
                    information: 'Informação',
                    devices_one: '{{count}} equipamento',
                    devices_other: '{{count}} equipamentos',
                    options: 'Opções',
                    client: 'Cliente',
                    info: 'Informações',
                    contract: 'Contrato',
                    expiringDevices: 'Equipamentos a expirar',
                    in30days: 'Dentro de 30 dias',
                    in90days: 'Dentro de 90 dias',
                    in60days: 'Dentro de 60 dias',
                    export: 'Exportar',
                    view: 'Consultar',
                    renew: 'Renovar',
                    close: 'Fechar',
                    contacts: 'Contactos',
                    noContacts: 'Sem contactos',
                    device: 'Equipamento',
                    brand: 'Marca',
                    model: 'Modelo',
                    serialNumber: 'Nº série',
                    gsmNumber: 'Nº gsm',
                    plan: 'Plano',
                    renewalDate: 'Data de renovação',
                    currentRenewalDate: 'Data de renovação actual',
                    nextRenewalDate: 'Próxima data de renovação',
                    cancel: 'Cancelar',
                    devices: 'Equipamentos',
                    validated: 'Validado',
                    clients: 'Clientes',
                    phoneNumber: 'Nº telefone',
                    data: 'Dados',
                    purchases: 'Compras',
                    recordSaved: 'Registo guardado',
                    recordDeleted: 'Registo eliminado',
                    addContact: 'Adicionar contacto',
                    billingInfo: 'Informações de pagamento',
                    add: 'Adicionar',
                    fullName: 'Nome completo',
                    address: 'Morada',
                    postalCode: 'Código postal',
                    city: 'Cidade',
                    contact: 'Contacto',
                    obs: 'Observations',
                    communications: 'Comunicações',
                    more24h: 'Há mais de 24h',
                    between12and24h: 'Entre 12 e 24h',
                    upToDate: 'Actualizado',
                    status: 'Estado',
                    location: 'Localização',
                    stopped: 'Parado',
                    openMap: 'Abrir mapa',
                    noLocation: 'Sem localização',
                    contracts: 'Contratos',
                    invoice: 'Factura',
                    confirmation: 'Confirmação',
                    selectDevice: 'Selecionar equipamento',
                    previous: 'Anterior',
                    next: 'Seguinte',
                    refresh: 'Actualizar',
                    version: 'Versão',
                    equipmentVersion: 'Versão de equipamento',
                    type: 'Tipo',
                    invoiceDate: 'Data factura',
                    register: 'Registar',
                    invoiceNumber: 'Nº factura',
                    id: 'Código',
                    creationDate: 'Data criação',
                    payment: 'Pagamento',
                    statusDate: 'Data estado',
                    device_s: 'Equipamento/s',
                    purchaseDetails: 'Detalhes compra',
                    total: 'Total',
                    seeDetails: 'Ver detalhes',
                    affectedDevices: 'Equipamentos afectados',
                    created: 'Criado',
                    general: 'Geral',
                    changePassword: 'Alterar senha',
                    remove: 'Remover',
                    updatePhoto: 'Alterar foto',
                    errorOldPass: 'Senha antiga errada',
                    oldPassword: 'Senha actual',
                    newPassword: 'Senha nova',
                    confirmPassword: 'Confirme a nova senha',
                    oldPasswordRequired: 'Senha antiga obrigatória',
                    newPasswordMustBe6Chars: 'Senha nova deve ter um mínimo de 6 caracteres. Caracteres especiais não permitidos',
                    passwordsMustMatch: 'A confirmação de senha não corresponde à senha nova',
                    newPasswordMustBeDifferentThanOldPassword : 'A senha nova deve ser diferente da senha actual',
                    errorLoginFailed: "Erro ao iniciar sessão",
                    login: 'Iniciar Sessão',
                    password: 'Senha',
                    noResults: 'Sem resultados',
                    pay : 'Pagar',
                    dates :
                    {
                        expires : 'Expira {{date, ago }}',
                        expired : 'Expirou {{date, ago }}'
                    },
                    cancelPurchaseQuestion  : 'Deseja cancelar a compra?',
                    confirm : 'Confirmar',
                    cancelPurchase : 'Cancelar compra',
                    MBPayment : 'Pagamento Multibanco',
                    entity : 'Entidade',
                    reference : 'Referência',
                    value : 'Valor',
                    paymentDeadline : 'Prazo pagamento',
                    creditDebitCardPayment : 'Pagamento cartão crédito/débito',
                    payFailed : 'Pagamento falhou!',
                    cardNumber : 'Número cartão',
                    expiryDate : 'Data de validade',
                    userDeviceInfo : 'Info equipamento',
                    viewClient : 'Consultar cliente',
                    clientRecord : 'Ficha do cliente',
                    back : 'Voltar',
                    pending : 'Pendente',
                    paid : 'Pago',
                    failed : 'Com falha',
                    canceled : 'Cancelado',
                    nonAvailable : 'Não disponível',
                    card : 'Cartão',
                    atm : 'Multibanco',
                    other : 'Outro',
                    youHaveContractsExpiring_one: 'Tem {{count}} contrato a expirar dentro de 30 dias',
                    youHaveContractsExpiring_other: 'Tem {{count}} contratos a expirar dentro de 30 dias',
                    clickToSeeMore : 'Clique para ver mais',
                    alerts : 'Alertas',
                    noAlerts : 'Sem alertas',
                    root: 'Raiz',
                    newFolder: 'Nova pasta',
                    editFolder: 'Editar pasta',
                    open: 'Abrir',
                    upload: 'Carregar',
                    download: 'Descarregar',
                    emptyFolder: 'Pasta vazia',
                    folderName: 'Nome da pasta',
                    clickOrDrag: 'Arraste ficheiros para aqui ou clique para selecionar...',
                    errorFileToBig: 'Ficheiro demasiado grande',
                    files : 'Ficheiros',
                    purchase : 'Compra',
                    date : 'Data',
                    number : 'Número',
                    invoices : 'Facturas',
                    n_A : 'N/D',
                    events : 'Eventos',
                    event : 'Evento',
                    settings : 'Definições',
                    notificationsSettings : 'Definições notificações',
                    notifications : 'Notificações',
                    push : 'Push',
                    eventsTypes  :
                    {
                        GEOFENCE_ENTER : 'Entrada de área',
                        GEOFENCE_EXIT  : 'Saida de área',
                        OVERSPEED  : 'Excesso de velocidade',
                        UNPLUG  : 'Desligado',
                        INPUT : 'Sinais',
                        SCHEDULE  : 'Horário',
                        TOW  : 'Reboque'
                    },
                    errorOcurredTryAgain : 'Ocorreu um erro. Tente novamente e se o problema persistir contacte-nos.',
                    paymentType : 'Tipo de pagamento',
                    selectPlan : 'Selecione um plano',
                    invalidPhoneNumber  : 'Nº telefone inválido',
                    locationNotAvailable  : 'Localização não disponível',
                    disabled : 'Inactivo',
                    editDevice  : 'Editar equipamento',
                    operationSuccess  : 'Operação efectuada com sucesso',
                    error : 'Erro',
                    logout : 'Terminar sessão',
                    dateTime : 'Data/Hora',
                    validateEmail : 'Validar email',
                    validateEmailInfo : 'O seu email tem que ser validado de forma a poder tirar partido da sua conta',
                    validationCode : 'Código de validação',
                    resendEmail : 'Reenviar email de validação',
                    submit : 'Submeter',
                    server_InvalidValidationRequest : 'Pedido de validação inválido',
                    server_InvalidTimeZone : 'Fuso horário inválido',
                    moving : 'Em movimento',
                    idle : 'Em relanti',
                    creditDebitCard : "Cartão crédito/débito",
                    purchaseCancelled : "Pagamento cancelado" ,
                    thankYou : "Obrigado",
                    thisDeviceCannotBeRenew : "Este equipamento não pode ser renovado",
                    pendingPurchase : 'Pagamento pendente',
                    pleaseGotoNovatronicaAngola : 'Por favor desloque-se à Novatronica Angola',
                    pleaseGotoNovatronicaPortugal : 'Por favor desloque-se à Novatronica Portugal',
                    thisDeviceCannotBeRenewSite : 'Este equipamento não pode ser renovado manualmente',
                    vat : 'Nº identificação fiscal',
                    updateBillingInfoQuestion : 'Deseja actualizar as informaçãos de pagamento?',
                    getBillingInfo : 'Obter informações de pagamento',
                    timeZone : 'Fuso horário',
                    smsBalance : "Saldo SMS",
                    lastUpdate : 'Última actualização',
                    forgotPassword : 'Esqueceu-se da senha?',
                    signUp : 'Registar',
                    annulled : 'Anulada'
                }
            }
        }
    });


export default i18n;