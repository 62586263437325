import React from "react";
import { Paper } from "@mui/material";
import { styled, darken } from '@mui/material/styles';
import Logo from "./Logo";

const Main = styled('main')(
    ({ theme }) => ({
        width: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: "auto",
        marginBottom: "auto",
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            flex: 1,
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
        }
    })
);

const FormArea = styled(Paper)(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        marginTop: 0
    })
);

const FormScreen = styled('div')(
    ({ theme }) => ({
        background: `linear-gradient(${theme.palette.primary.main}, ${darken(theme.palette.primary.main, 0.3)})`,
        height: "100vh",
        display: "flex",
        padding: `${theme.spacing(2)}px 0`,
        overflow: "auto"
    })
);

export default function FormPage({ children }) {
    return (
        <FormScreen>
            <Main>
                <Logo />
                <FormArea>
                    {children}
                </FormArea>
            </Main>
        </FormScreen>
    )
}