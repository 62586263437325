export function arraySort(array, keys, formatFields)
{
    let data = array.slice();

    keys = keys || {};

    // via
    // http://stackoverflow.com/questions/5223/length-of-javascript-object-ie-associative-array
    var obLen = function(obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key))
                size++;
        }
        return size;
    };

    // avoiding using Object.keys because i guess did it have IE8 issues?
    // else var obIx = fucntion(obj, ix){ return Object.keys(obj)[ix]; } or
    // whatever
    var obIx = function(obj, ix) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (size == ix)
                    return key;
                size++;
            }
        }
        return false;
    };

    var keySort = function(a, b, d) {
        d = d !== null ? d : 1;
        // a = a.toLowerCase(); // this breaks numbers
        // b = b.toLowerCase();
        if (a == b)
            return 0;
        else if (a === null || a === '')
            return d;
        else if (b === null || b === '')
            return -d;

        // Sort numbers in string
        var _a = Number(a), _b = Number(b);
        if (_a == a && _b == b)  return _a > _b ? 1 * d : -1 * d;
        else return (a || '').toString().toLowerCase().trim() > (b || '').toString().toLowerCase().trim() ? 1 * d : -1 * d;
    };

    var resolvePath = function(obj, path)
    {
        var properties = Array.isArray(path) ? path : path.split('.')
        return properties.reduce(function(prev, curr) { return prev && prev[curr]; }, obj);
    }

    var KL = obLen(keys);

    if (!KL) return data.sort(keySort);

    for ( var k in keys) {
        // asc unless desc or skip
        keys[k] =
                keys[k] == 'desc' || keys[k] == -1  ? -1
              : (keys[k] == 'skip' || keys[k] === 0 ? 0
              : 1);
    }

    data.sort(function(a, b) {
        var sorted = 0, ix = 0;

        while (sorted === 0 && ix < KL)
        {
            var k = obIx(keys, ix);
            if (k)
            {
                var dir = keys[k];

                var fieldA = resolvePath(a, k);
                var fieldB = resolvePath(b, k);

                if (formatFields && formatFields[k])
                {
                    var formatFc = formatFields[k];

                    fieldA = formatFc(a);
                    fieldB = formatFc(b);
                }

                sorted = keySort(fieldA, fieldB, dir);
                ix++;
            }
        }
        return sorted;
    });
    return data;
};

export function arrayToText(arr, separator = ' ')
{
    return arr.filter(e=> e != null).join(separator)
}

export function applyFilters(data, fields, searchQuery)
{
    const searchText = (searchQuery || '').trim().toLowerCase();
    if (searchQuery.length == 0) return data;

    const matchesSearchText = (s) => {
        return fields.some(prop =>
        {
            let value = '';
            if (typeof prop === 'object')
            {
                const { key, formatter, validator } = prop;

                if (formatter) value = formatter(key, s);
                else if (key) value = s[key];

                 //value = prop(s, searchText);
            } else value = s[prop];

            if (typeof value === 'number' && !Number.isNaN(value)) value = `${value}`;
            else if (value == null || value == undefined) value = '';

            return value.toLowerCase().trim().includes(searchText);

        });
    };
    return (data || []).filter(s => matchesSearchText(s));
}

export function hasError(errors, name) {
    return errors && name in errors;
}

export function getErrorMessage(errors, name) {
    return errors && errors[name] && errors[name].message;
}
