import { Navigate, useRoutes } from 'react-router-dom';
import Layout from './layout';
import Device from './pages/Device';
import Devices from './pages/Devices';
import Login from './pages/Login';
import Profile from './pages/Profile/Profile';
import Purchases from './pages/Purchases';
import Events from './pages/Events';
import { useIsLoggedIn } from './store/authentication';

export default function Routes() {
    const isLoggedIn = useIsLoggedIn();
    return useRoutes([
        {
            path: '/app',
            element: isLoggedIn ? <Layout /> : <Navigate to="/" />,
            children: [
                { path: '', element: <Navigate to="/app/devices/list" replace /> },
                {
                    path: 'devices',
                    children: [
                        { path: '', element: <Navigate to="/app/devices/list" replace /> },
                        { path: 'list', element: <Devices /> },
                        { path: ':id', element: <Device /> },
                        { path: ':id/edit', element: <Device edit={true} /> },
                        { path: ':id/renew', element: <Device renew={true} /> }
                    ]
                },
                {
                    path: 'purchases',
                    children: [
                        { path: '', element: <Navigate to="/app/purchases/list" replace /> },
                        { path: 'list', element: <Purchases /> },
                        { path: ':id', element: <Purchases detailsOpen={true} /> },

                    ]
                },
                {
                    path: 'events',
                    children: [
                        { path: '', element: <Navigate to="/app/events/list" replace /> },
                        { path: 'list', element: <Events /> },
                        { path: ':id', element: <Events detailsOpen={true} /> },

                    ]
                },
                {
                    path: 'profile',
                    children: [
                        { path: '', element : <Navigate to="/app/profile/general" replace /> },
                        { path: ':tab', element: <Profile /> }
                    ]
                },
                //{ path: 'renew', element: <RenewDevices /> }
            ]
        },
        {
            path: '/',
            element: !isLoggedIn ? <Login /> : <Navigate to="/app" />,
        },

        { path: '*', element: <Navigate to="/app" replace /> }
    ]);
}