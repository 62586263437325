import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";
import { StripeCardDetails, StripeContextProvider } from "@nvapps/common/components/stripe";
import StatusAlert from '@nvapps/common/components/ui/StatusAlert';
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

function CardPaymentFormDialog({ open, onDone, onCancel, onClose, fetchPaymentDetails, translations, locale = 'pt' }) {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(null);
    const [complete, setComplete] = useState(null);
    const stripe = useStripe();
    const elements = useElements();

    const getTranslation = (key, defaultValue) => translations && translations[key] ? translations[key] : defaultValue;

    const handlePay = () => {
        setLoading(true);
        fetchPaymentDetails().then(async res => {
            if (res.status === 200) {
                const card = elements.getElement(CardNumberElement);
                const { error } = await stripe.confirmCardPayment(res.data.stripeClientSecret, {
                    payment_method: { card }
                });
                if (error) {
                    setError(true);
                } else {
                    onDone && onDone();
                }
            } else {
                setError(true);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth={true}>
            <DialogTitle id="alert-dialog-title">
                {getTranslation('creditDebitCardPayment', 'Credit/Debit Card Payment')}
            </DialogTitle>
            <DialogContent>
                <StripeCardDetails translations={translations} onCompleteChange={(complete) => setComplete(complete)} />
                {error}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    {getTranslation('cancel', 'Cancel')}
                </Button>
                <ButtonWithProgress
                    color="primary"
                    disabled={!complete }
                    loading={loading}
                    onClick={handlePay}>
                    {getTranslation('pay', 'Pay')}
                </ButtonWithProgress>
            </DialogActions>
            <StatusAlert
                visible={Boolean(error)}
                onClose={() => setError(false)}
                variant="error"
                message= {getTranslation('payFailed', 'Pay failed')}>
            </StatusAlert>
        </Dialog>
    );
}

// const InjectedCardPaymentFormDialog = injectStripe(CardPaymentFormDialog);

export default function CardPaymentModal(props) {
    return (
        <StripeContextProvider>
            <CardPaymentFormDialog {...props} />
        </StripeContextProvider>
    );
}