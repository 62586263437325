import { Icon } from '@iconify/react';
import { InputAdornment, OutlinedInput, Toolbar as BaseToolbar, Tooltip, Typography, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const ToolbarStyle = styled(BaseToolbar)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 0, 3)
}));


export default function TableToolbar({ children, ...props }) {

    return <ToolbarStyle {...props}>
        {children}
    </ToolbarStyle>
}

/*export default function TableToolbar({ numSelected, searchQuery, onSearch, selectedText = '0 selecionadas', searchPlaceholder = 'Pesquisar...', deleteTooltip = 'Eliminar', filterListTooltip = 'Filtrar lista' }) {

    return <ToolbarStyle sx={{
        ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
        })
    }}>
        {numSelected > 0 ? (
            <Typography component="div" variant="subtitle1">
                {selectedText}
            </Typography>
        ) : (<SearchBox searchQuery={searchQuery} searchPlaceholder={searchPlaceholder} onSearch={onSearch} />)}
        {numSelected > 0 ? (
            <Tooltip title={deleteTooltip}>
                <IconButton>
                    <Icon icon="eva:trash-2-fill" />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title={filterListTooltip}>
                <IconButton>
                    <Icon icon="ic:round-filter-list" />
                </IconButton>
            </Tooltip>
        )}
    </ToolbarStyle>
}*/