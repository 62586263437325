import { Button, Menu } from '@mui/material';
import { useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


export default function ButtonPopover({ menuPopoverProps = {}, text, position = "bottom", children, ...props }) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const isTop = position.indexOf("top") > -1;
    const isLeft = position.indexOf("right") == -1;

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleOpen}
                variant="contained"
                endIcon={isTop ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                {...props}>
                {text}
            </Button>
            <Menu
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                anchorEl={anchorRef.current}
                sx={{ minWidth: 220 }}
                transformOrigin={{ horizontal: isLeft ? 'left' : 'right', vertical: isTop ? 'bottom' : 'top' }}
                anchorOrigin={{ horizontal: isLeft ? 'left' : 'right', vertical: isTop ? 'top' : 'bottom' }}
                PaperProps={{
                    sx: {
                        overflow: 'inherit',
                        boxShadow: (theme) => theme.customShadows.z20,
                        border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`
                    }
                }}
                {...menuPopoverProps}
            >
                {children}
            </Menu>
        </>
    );
}
