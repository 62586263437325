import { Box, Button, ButtonGroup, Popover, Stack, Typography } from "@mui/material";

export default function ConfirmPopover({ text, open, onClose, onResolve, anchorEl, confirmText = 'Sim', rejectText = 'Não', ...props})
{
    const handleResolve = value =>
    {
        onResolve && onResolve(value);
        onClose();
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            modifiers={{ arrow : true }}
            PaperProps={{
                sx: { width: 200, maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            {...props}
        >
            <Box p={1}>
                <Stack spacing={1} >
                <Typography variant="subtitle2" textAlign="center">{text}</Typography>
                <ButtonGroup fullWidth size="small" variant="text"  >
                    <Button onClick={() => handleResolve(true)}>{confirmText}</Button>
                    <Button onClick={() => handleResolve(false)}>{rejectText}</Button>
                </ButtonGroup>
                </Stack>
            </Box>
        </Popover>);
}
