import { parseISO } from "date-fns";
import { differenceInSeconds } from "date-fns/esm";

export const ContractStatusEnum =
{
    EXPIRED: 'Expired',
    EXPIRING: 'Expiring',
    ACTIVE: 'Active',
    NODATA: 'NoData'
}

export const ContractStatus =
{
    Expired: { value: 'Expired', color: 'error', text: 'expired' },
    Expiring: { value: 'Expiring', color: 'warning', text: 'expiring' },
    Active: { value: 'Active', color: 'success', text: 'regularized' },
    NoData: { value: 'NoData', color: 'secondary', text: 'regularized' },
}

export const getStatusColorOfContract = (datetime) => ContractStatus[getStatusOfContract(datetime)].color;
export const getStatusTextOfContract = (datetime) => ContractStatus[getStatusOfContract(datetime)].text;

export function getStatusOfContract(datetime, dateCompare = new Date()) {

    if (!datetime) return ContractStatusEnum.NODATA;

    const dateObj = datetime instanceof Date ? datetime : parseISO(datetime);
    const dateCompareObj = dateCompare instanceof Date ? dateCompare : parseISO(dateCompare);

    const diffSeconds = differenceInSeconds(dateObj, dateCompareObj);
    const diffDays = diffSeconds / 60 / 60 / 24;

    if (diffSeconds < 0) return ContractStatusEnum.EXPIRED;
    else if (diffDays < 30) return ContractStatusEnum.EXPIRING;
    else return ContractStatusEnum.ACTIVE;
}

export const PositionCommunicationsEnum =
{
    MORE24H: 'More24h',
    BETWEEN12AND24H: 'Between12And24H',
    UPTODATE: 'UpToDate'
}

export const PositionCommunications =
{
    More24h: { color: 'error', text: 'more24h' },
    Between12And24H: { color: 'warning', text: 'between12and24h' },
    UpToDate: { color: 'success', text: 'upToDate' },
}

export function getStatusOfPositionCommunication(datetime, dateCompare = new Date()) {

    const dateObj = datetime instanceof Date ? datetime : parseISO(datetime);
    const dateCompareObj = dateCompare instanceof Date ? dateCompare : parseISO(dateCompare);

    const diffSeconds = differenceInSeconds(dateCompareObj, dateObj);
    const diffHours = diffSeconds / 60 / 60;
    // console.log('diffHours', dateObj, dateCompareObj, diffSeconds, diffHours)

    if (diffHours >= 24) return PositionCommunicationsEnum.MORE24H;
    else if (diffHours >= 12) return PositionCommunicationsEnum.BETWEEN12AND24H;
    else return PositionCommunicationsEnum.UPTODATE;
}

export const PositionStatusEnum =
{
    IDLE: 'Idle',
    MOVING: 'Moving',
    OFF: 'Off',
    NOLOCATION: 'NoLocation'
}

export const PositionStatus = {
    Idle: { color: 'warning', text: 'idle' },
    Moving: { color: 'success', text: 'moving' },
    Off: { color: 'error', text: 'stopped' },
    NoLocation: { color: 'secondary', text: 'noLocation' },
}

export function getStatusOfPosition(status) {
    return status ? PositionStatus[PositionStatusEnum[status]] : PositionStatus.NoLocation;
}

export const PaymentTypesEnum =
{
    CARD: 'Card',
    MULTIBANCO: 'Multibanco',
    OTHER: 'Other'
}

export const PaymentTypes =
{
    'Card': { text: 'card' },
    'Multibanco': { text: 'atm' },
    'Other': { text: 'other' },
}

export const PurchasesStatusEnum =
{
    PENDING: 'Pending',
    PAID: 'Paid',
    FAILED: 'Failed',
    CANCELED: 'Canceled',
    ANNULLED : 'Annulled'
}

export const PurchasesStatus =
{
    'Pending': { color: 'warning', text: 'pending' },
    'Paid': { color: 'success', text: 'paid' },
    'Failed': { color: 'error', text: 'failed' },
    'Canceled': { color: 'error', text: 'canceled' },
    'Annulled': { color: 'error', text: 'annulled' },
    'NA': { color: 'default', text: 'nonAvailable' },
}

export function getPurchaseStatus(status) {
    return status ? PurchasesStatus[status] : PurchasesStatus.NA;
}

/*export const PaymentsByCurrency =
{
    eur: ['Multibanco'],
    //aoa : ['Card']
}

export function getPaymentTypesByCurrency(renewalPermission = {}) {

    const { renewalMethods } = renewalPermission;
    return PaymentsByCurrency[currency] ?? [];
}*/

export const DeviceTypesIcons =
{
    'NVAuto': 'mdi:car',
    'NVMoto': 'mdi:bike'
}

export const RenewalCountriesInfo =
{

    'ao' :
    {
        text : 'pleaseGotoNovatronicaAngola'
    },
    'pt' :
    {
        text : 'pleaseGotoNovatronicaPortugal'
    }
}

export function getRenewalCountryInfo(country)
{
    const info = RenewalCountriesInfo[(country || '').toLowerCase()];
    return info ? info.text : null;
}