import axios from "axios";
import { authRequest } from "./utils";

const usersApiBaseUrl = ""; //"https://nvtracker.nvapps.novatronica.com";

export const axiosInstance = axios.create();

export function getDevices() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/devices`,
    });
}

export function updateDevice(id, data = { name : null, brand: null, model: null }) {
    return authRequest({
        method: "PUT",
        url: `${usersApiBaseUrl}/api/devices/${id}`,
        data
    });
}

export function renewalDevice(id, renewalForm = { paymentType: "Card", planId : 0 }) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/devices/${id}/Renewal`,
        data: renewalForm
    });
}

export function getDevicesPositions() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/devicesPositions`,
    });
}

export function getPurchases() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/purchases`,
    });
}

export function getPlans() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/plans`,
    });
}

export function getPlan(id) {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/plans/${id}`,
    });
}

/*export function createPurchase(purchaseForm = { deviceIds: [], paymentType: "Card", planId : 0 }) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/purchases`,
        data: purchaseForm
    });
}*/

export function cancelPurchase(purchaseId) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/purchases/${purchaseId}/cancel`,
        data: {}
    });
}

export function getPaymentDetails(purchase) {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/purchases/${purchase.id}/paymentDetails/${purchase.paymentType}`,
    });
}


export function getEvents() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/events`,
    });
}

export function getEvent(id) {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/events/${id}`,
    });
}

export const getEventsPagedUrl = `${usersApiBaseUrl}/api/events/paged`

export const getDeviceTypesUrl = `${usersApiBaseUrl}/api/devicesTypes`

export function getDeviceRenewalPermission(deviceId) {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/devices/${deviceId}/RenewalPermission`,
    });
}