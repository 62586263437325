import React from 'react';
import { Typography, useTheme, Box, Stack } from '@mui/material';

export function FieldList({ children, sx = {}, ...props }) {
    return (
        <Stack spacing={2} sx={sx} {...props}>
            {children}
        </Stack>
    );
}

export function Field1({ title, value }) {
    return (
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "top", padding: "5px 16px"}}>
            <Typography variant="subtitle2" style={{lineHeight: 1, opacity: 0.75, marginRight: 15}}>
                {title}
            </Typography>
            <Typography variant="body2">
                {value}
            </Typography>
        </div>
    )
}

export function Field({ title, value, titleTypographyProps = {}, valueTypographyProps = {}, ...props }) {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" {...props} >
            <Typography variant="body2" color="text.secondary" mr={2} {...titleTypographyProps}>
                {title}
            </Typography>
            <Typography variant="subtitle2" {...valueTypographyProps}>
                {value}
            </Typography>
        </Box>
    )
}

export function HorizontalFieldList({ children, style, ...props }) {
    const theme = useTheme();
    const mergedStyle = {
        padding: theme.spacing(1.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        ...style
    };

    return (
        <div style={mergedStyle} {...props}>
            {children}
        </div>
    );
}

export function HorizontalField({ title, value }) {
    const theme = useTheme();
    return (
        <div style={{textAlign: "center"}}>
            <div style={{height: theme.typography.body1.lineHeight + "em"}}>
                {value}
            </div>
            <div style={{textTransform: "uppercase", fontSize: "0.9em", opacity: 0.75}}>
                {title}
            </div>
        </div>
    );
}