import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Divider, IconButton, ListItemIcon, MenuItem, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import MenuPopover from '@nvtracker/common/components/MenuPopover';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useAuthenticationActions } from '../store/authentication';
import { useProfilePhoto, useUserProfile } from '../store/user';

const MENU_OPTIONS = [
    {
        label: 'profile',
        icon : 'bxs:user-detail',
        linkTo: 'profile'
    }
];

export default function AccountPopover()
{
    const { profile } = useUserProfile();
    const { t } = useTranslation();
    const { photo } = useProfilePhoto();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { logout } = useAuthenticationActions();

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <Avatar src={photo} alt="photoURL" />
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {profile.name || profile.userName}
                    </Typography>
                    {profile.name && (
                    <Typography variant="body2" color='textSecondary' noWrap>
                        {profile.userName}
                    </Typography>)}
                </Box>

                <Divider sx={{ my: 1 }} />

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                    >
                        {option.icon &&
                         <ListItemIcon sx={{ mr: 1.5 }}>
                            <Icon icon={option.icon} width={24} height={24} />
                        </ListItemIcon>}
                        {t(option.label)}
                    </MenuItem>
                ))}

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button fullWidth onClick={() => logout()} variant="outlined">
                        <Trans>logout</Trans>
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
