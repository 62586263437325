import { useFetchV2 } from '@nvapps/common/hooks';
import { createSlice } from '@reduxjs/toolkit';
import { isBefore } from 'date-fns/esm';
import * as nvtrackerApi from '../api/nvtracker';
import { useReduxFetch } from '../hooks';
import { registerSubscription } from '../mqtt';

const initialState = {
    isFetching: false,
    fetched: false,
    error: null,
    byId: {},
    allIds: []
};

const devices = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        fetchStart(state) {
            state.isFetching = true;
        },
        fetchFailed(state, action) {
            state.isFetching = false;
            state.error = action.payload;
        },
        fetchSuccess(state, action) {
            state.isFetching = false;
            state.fetched = true;
            state.allIds = action.payload.map(d => d.id);
            state.byId = Object.fromEntries(action.payload.map(d => [d.id, d]));
        },
        updateDevice(state, action) {
            state.byId[action.payload.id] = action.payload;
        },
        addDevice(state, action) {
            state.allIds.push(action.payload.id);
            state.byId[action.payload.id] = action.payload;
        },
        deleteDevice(state, action) {
            state.allIds = state.allIds.filter(e=> e !== action.payload);
            const newState = {...state.byId};
            delete newState[action.payload];
            state.byId = newState;
        }
    }
});

export function fetchDevices(refetch = false) {

    return (dispatch, getState) => {
        const state = getState();
        if (state.devices.isFetching || (refetch === false && state.devices.fetched)) return;
        dispatch(devices.actions.fetchStart());
        return nvtrackerApi.getDevices().then(
            res => {
                dispatch(devices.actions.fetchSuccess(res.data));
                registerSubscription("devices", function (topic, data) {
                    switch (data.action) {
                        case "add":
                            return dispatch(devices.actions.addDevice(data.payload));
                        case "update":
                            return dispatch(devices.actions.updateDevice(data.payload));
                        case "delete":
                        {
                            const id = topic.split("/").pop();
                            if (id) return dispatch(devices.actions.deleteDevice(id));
                            break;
                        }
                        default:
                            throw new Error("Invalid action");
                    }
                });
            },
            err => dispatch(devices.actions.fetchFailed(err))
        );
    }
}

export function getDevice(state, id) {
    const devices = state.devices;
    return devices.byId[id];
}

export function getDevices(state) {
    const devices = state.devices;
    return devices.allIds.map(id => devices.byId[id]);
}

export function isDeviceExpired(d)
{
    return !d || d.expiredPlan || isBefore(new Date(d.renewalDate), new Date());
}

export function isFetchingDevices(state) {
    return state.devices.isFetching;
}

export function devicesFetchError(state) {
    return state.devices.error;
}

export const useDevices = (devicesSelector = getDevices) => {
    return useReduxFetch(fetchDevices, isFetchingDevices, devicesSelector, devicesFetchError);
}


export const useDevice = (id) => {
    return useDevices(state => getDevice(state, id));
}

export function useDeviceRenewalPermission(deviceId)
{
    return useFetchV2(deviceId ? () => nvtrackerApi.getDeviceRenewalPermission(deviceId) : null, [deviceId]);
}

export default devices.reducer;