import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import ButtonWithProgress from '@nvapps/common/components/ui/ButtonWithProgress';
import { getCurrencySymbol } from '@nvapps/common/utils';
import { PaymentTypes } from '@nvtracker/common/helpers';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { renewalDevice } from '../../../api/nvtracker';
import { useCallApi } from '../../../hooks';

export function PaymentStep({ device, plan, billingInfo, renewalPermission, onNext, onBack }) {

    const paymentsTypes = renewalPermission.renewMethods || [];
    const [paymentType, setPaymentType] = useState(paymentsTypes[0]);
    const { t } = useTranslation();
    const [callApi, isSaving] = useCallApi({ errorText : t('errorOcurredTryAgain') });

    const handleClickNext = async () => {

        const res = await callApi(renewalDevice(device.id, { paymentType, planId : plan.id, billingInfo }));
        if (res !== false) onNext && onNext(res.data);
    };

    return (
        <React.Fragment>
            <Typography align="left" variant='body2'>
                <Trans>plan</Trans>
            </Typography>
            <Typography variant="subtitle1" align="left" gutterBottom>
                 {`${plan.name}`}
            </Typography>
            <Typography align="left" variant='body2'>
                <Trans>total</Trans>
            </Typography>
            <Typography variant="h5" align="left" color="primary.main" gutterBottom>
                {plan.price} {getCurrencySymbol(plan.currency)}
            </Typography>
            <Divider variant="fullWidth" />
            <FormControl component="fieldset" style={{ margin: "8px 0", marginTop: "16px" }}>
                <FormLabel component="legend"><Trans>paymentType</Trans></FormLabel>
                <RadioGroup
                    aria-label="payment type"
                    name="paymentType"
                    value={paymentType}
                    onChange={e => setPaymentType(e.target.value)}>
                    {paymentsTypes.map(p => <FormControlLabel key={p} value={p} control={<Radio />} label={<Trans>{PaymentTypes[p].text}</Trans>} />)}
                </RadioGroup>
            </FormControl>
            <Box display={"flex"}>
                <Button
                    onClick={onBack}
                    sx={{ mr : 1 }}
                    disabled={isSaving}>
                    <Trans>back</Trans>
                </Button>
                <ButtonWithProgress
                    variant="contained"
                    color="primary"
                    disabled={paymentsTypes.length === 0}
                    loading={isSaving}
                    onClick={handleClickNext}>
                    <Trans>pay</Trans>
                </ButtonWithProgress>
            </Box>
        </React.Fragment>
    );
}
