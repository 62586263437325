import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ptPT } from '@mui/material/locale';
import ThemeConfig from '@nvapps/common/theme';
import ptLocale from 'date-fns/locale/pt';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import store from './store';
import history from './store/history';

function App() {
  return (
        <Provider store={store}>
            <ThemeConfig type="light" language={ptPT}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                    <SnackbarProvider maxSnack={3} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                        <Router history={history}>
                            <Routes />
                        </Router>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeConfig>
        </Provider>
  );
}

export default App;


 /*<Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <AuthPageWrapper>
            <Devices />
            <Route path="/upgrade">
              {({ match }) => <UpgradeDevices open={!!match} />}
            </Route>
            <Route path="/renewal">
              {({ match }) => <UpgradeDevices renewal open={!!match} />}
            </Route>
            <Route path="/purchases">
              {({ match }) => <Purchases open={!!match} />}
            </Route>
            <Route path="/edit-profile">
              {({ match }) => <EditProfile open={!!match} />}
            </Route>
            <Route path="/change-password">
              {({ match }) => <ChangePassword open={!!match} />}
            </Route>
          </AuthPageWrapper>
        </Router>
      </MuiThemeProvider>
    </Provider>*/