import axios from "axios";
import { getJwtTokenCookie, getAuthenticatedImage as getAuthenticatedImageUtils, deleteJwtTokenCookie } from "@nvapps/common/utils";

export const axiosInstance = axios.create();

// Add a response interceptor
axiosInstance.interceptors.response.use(response => response, function (error) {
    if (error.response.status === 401) {
        deleteJwtTokenCookie();
        window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
});

export function authRequest({ headers, ...opts }) {
    const token = getJwtTokenCookie();
    return axiosInstance({
        method: "GET",
        headers: { Authorization: `Bearer ${token}`, ...headers },
        ...opts
    });
}

export async function getAuthenticatedImage(url) {
    return getAuthenticatedImageUtils(url, getJwtTokenCookie());
}

export const usersApiBaseUrl = "https://users.nvapps.novatronica.com";
export const usersPagesBaseUrl = "https://users.nvapps.novatronica.com";

// export const usersApiBaseUrl = "https://localhost:44373";
// export const usersPagesBaseUrl = "http://localhost:3002";
