import { createSlice, createAction } from '@reduxjs/toolkit'
import { parseJwt, isValidJwtToken, setJwtTokenCookie, deleteJwtTokenCookie } from '@nvapps/common/utils';
import * as authenticationApi from '../api/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

function getUserStateByToken(token) {
    const tokenData = token ? parseJwt(token) : null;
    return {
        isAuthenticated: isValidJwtToken(tokenData),
        email: tokenData && tokenData.name,
        name: tokenData && tokenData.full_name,
        loginFailed: false,
        loginStarted: false
    }
}

const initialState = getUserStateByToken(null);

const authentication = createSlice({
    name: 'authentication',
    initialState,
    reducers: {

        loginStart(state) {
            state.loginStarted = true;
            state.loginFailed = false;
        },
        loginFailed(state) {
            state.loginStarted = false;
            state.loginFailed = true;
        },
        loginSuccess(state, action) {
            Object.assign(state, getUserStateByToken(action.payload));
        }
    }
});

export function login(credentials) {
    return (dispatch) => {
        dispatch(authentication.actions.loginStart());
        return authenticationApi.login(credentials).then(
            res => {
                setJwtTokenCookie(res.data.token);
                return dispatch(authentication.actions.loginSuccess(res.data.token));
            },
            err => dispatch(authentication.actions.loginFailed())
        );
    }
}

export const logout = createAction("authentication/logout", function prepare() {
    deleteJwtTokenCookie();
    return {};
});

export const { loginSuccess } = authentication.actions;

export function useAuthenticationActions() {
    const dispatch = useDispatch();

    return useMemo(() => {
        return {
            login : credentials => dispatch(login(credentials)),
            logout: () => dispatch(logout())
        };
    }, [dispatch]);
}

export function useIsLoggedIn() {
    const state = useSelector(state => state.authentication.isAuthenticated);
    return state;
}

export function useAuthenticationState() {
    const state = useSelector(state => state.authentication);
    return state;
}


export default authentication.reducer;