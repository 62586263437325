import { authRequest, getAuthenticatedImage, usersApiBaseUrl } from "./utils";

export function changePassword(changePasswordForm) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile/changePassword`,
        data: changePasswordForm
    });
}

export function getBillingInformation() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/profile/billingInformation`,
    });
}

export function updateBillingInformation(billingInfo = {
    "fullName": "",
    "address": "",
    "city": "",
    "postalCode": "",
    "country": "",
    phoneNumber : '',
    vat : ''
}) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile/billingInformation`,
        data: billingInfo
    });
}

export const getProfileUrl = `${usersApiBaseUrl}/api/profile`;

export function getProfile() {
    return authRequest({
        method: "GET",
        url: getProfileUrl
    });
}

export function updateProfile(profileForm) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile`,
        data: profileForm
    });
}

export function updatePicture(picture) {

    var formData = new FormData();
    formData.append("image", picture);

    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile/photo`,
        data : formData,
        headers :
        {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export const profilePhotoUrl = `${usersApiBaseUrl}/api/profile`;

export async function getProfilePhoto(picture)
{
    return picture ? getAuthenticatedImage(`${profilePhotoUrl}/${picture}`) : null;
}

export function resendValidationEmail() {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile/resendValidationCode`
    });
}

export function validateEmail(validationCode) {
    return authRequest({
        method: "POST",
        url: `${usersApiBaseUrl}/api/profile/validate`,
        data : { validationCode }
    });
}

export function getTimeZones() {
    return authRequest({
        method: "GET",
        url: `${usersApiBaseUrl}/api/profile/timezones`
    });
}