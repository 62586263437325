import { Dialog as MaterialDialog } from '@mui/material';
import React from 'react';
import { useIsMobile } from '../../hooks';

export default function Dialog({ children, maxWidth = 'xs', disableBackdropClick = true, onClose, ...props })
{
    const isMobile = useIsMobile();

    const handleClose = (event, reason) =>
    {
        if (disableBackdropClick && reason === 'backdropClick') return;
        onClose && onClose();
    }

    return (
         <MaterialDialog onClose={handleClose} disableEscapeKeyDown={true} maxWidth={maxWidth} fullWidth fullScreen={isMobile} {...props}>
            {children}
         </MaterialDialog>
    )
}
