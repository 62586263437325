import { connect } from "mqtt";
import { parseJwt } from '@nvapps/common/utils';

let subscriptions = [];
let mqttClient;
let baseTopic = "";

export function registerSubscription(topic, callback) {
    subscriptions.push({ topic, callback });
}

export function startMqttClient(token) {
    //const protocol = window.location.protocol.startsWith("https") ? "wss" : "ws";

    mqttClient = connect(`wss://mqtt.nvapps.novatronica.com/`, {
        username: "nvapps-jwt",
        password: token
    });

    mqttClient.on('connect', function () {
        const tokenData = parseJwt(token);

        baseTopic = `nvapps/users/${tokenData.sub}/nvtracker/`;

        mqttClient.subscribe(`${baseTopic}#`, function (err) {
            if (err) console.error(err);
        });
    });

    mqttClient.on('message', function (topic, message) {
        for (const sub of subscriptions) {
            if (topic.startsWith(baseTopic + sub.topic)) {
                sub.callback(topic.substr(baseTopic.length), JSON.parse(message));
            }
        }
    });
}

export function resetMqttClient() {
    if (!mqttClient) return;
    mqttClient.end();
    mqttClient = null;
    subscriptions = [];
    baseTopic = "";
}


