import { createSlice } from '@reduxjs/toolkit'
import * as nvtrackerApi from '../api/nvtracker';
import { useReduxFetch } from '../hooks';
import { registerSubscription } from '../mqtt';

const initialState = {
    isFetching: false,
    fetched: false,
    error: null,
    byId: {},
    allIds: []
};

const devicesPositions = createSlice({
    name: 'devicesPositions',
    initialState,
    reducers: {
        fetchStart(state) {
            state.isFetching = true;
        },
        fetchFailed(state, action) {
            state.isFetching = false;
            state.error = action.payload;
        },
        fetchSuccess(state, action) {
            state.isFetching = false;
            state.fetched = true;
            state.allIds = action.payload.map(d => d.deviceId);
            state.byId = Object.fromEntries(action.payload.map(d => [d.deviceId, d]));
        },
        updatePosition(state, action) {
            const deviceId = action.payload.deviceId;
            if (deviceId in state.byId) {
                state.byId[deviceId] = {
                    ...state.byId[deviceId],
                    ...action.payload
                };
            }
        }
    }
});

export function fetchDevicesPositions() {
    return async (dispatch, getState) => {
        const state = getState();
        if (state.devicesPositions.isFetching || state.devicesPositions.fetched) return;
        dispatch(devicesPositions.actions.fetchStart());
        nvtrackerApi.getDevicesPositions().then(
            res => {

                dispatch(devicesPositions.actions.fetchSuccess(res.data));
                registerSubscription("positions", function (topic, data) {
                    switch (data.action) {
                        case "update":
                            return dispatch(devicesPositions.actions.updatePosition(data.payload));
                        default:
                            throw new Error("Invalid action");
                    }
                });
            },
            err => dispatch(devicesPositions.actions.fetchFailed(err))
        );
    }
}

function getPosition(state, deviceId) {
    const devicesPositions = state.devicesPositions;
    return devicesPositions.byId[deviceId];
}

function getDevicesPositions(state) {
    const devicesPositions = state.devicesPositions;
    return devicesPositions.allIds.map(id => devicesPositions.byId[id]);
}

function isFetchingDevicesPositions(state) {
    return state.devicesPositions.isFetching;
}

function devicesPositionsFetchError(state) {
    return state.devicesPositions.error;
}

export const useDevicesPositionsWithSelector = (devicePositionsSelector) => {
    return useReduxFetch(
        fetchDevicesPositions,
        isFetchingDevicesPositions,
        devicePositionsSelector,
        devicesPositionsFetchError);
}

export const useDevicesPositions = () => {
    return useDevicesPositionsWithSelector(getDevicesPositions);
}

export const useDevicesPosition = (deviceId) => {
    return useDevicesPositionsWithSelector(state => getPosition(state, deviceId));
}

export default devicesPositions.reducer;