import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import useId from '@mui/utils/useId';

export default function AutoCompleteSelect({ value, options, label, onChange, textFieldProps }) {
    const option = options[value];
    const id = useId();

    return (
        <Autocomplete
            id={id}
            options={Object.values(options)}
            autoHighlight
            value={option || null}
            getOptionLabel={option => option.label || 'N/A'}
            onChange={onChange}
            renderInput={params => (
                <TextField
                    {...params}
                    {...textFieldProps}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        ...(textFieldProps && textFieldProps.inputProps),
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }} />
            )} />
    );
}
