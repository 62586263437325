import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import ButtonWithProgress from "@nvapps/common/components/ui/ButtonWithProgress";
import { Field, FieldList } from "@nvapps/common/components/ui/Fields";
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { PhoneNumber } from "@nvapps/common/components/ui/PhoneInput";
import Dialog from '@nvapps/common/components/ui/Dialog';
import { getErrorMessage, hasError } from '@nvtracker/common/utils';
import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { updateDevice } from "../../api/nvtracker";
import DateTime from "../../components/Ui/DateTime";
import { useSaveApi } from "../../hooks";
import { useDevice } from "../../store/devices";
import { usePlanById } from "../../store/plans";
import DeviceTypeLabel from "../Ui/DeviceTypeLabel";
import { isDeviceActive } from "@nvtracker/common/devices";

function getErrors(device) {
    const err = [];
    if (!device) return null;

    if ((device.name || '').trim().length === 0) err.push({ id: "name", message: "requiredField" });

    return err.length > 0 ? Object.fromEntries(err.map(e => [e.id, e])) : null;
}

function Form({ device, errors, onChange }) {

    const { t } = useTranslation();
    const [plan] = usePlanById(device.planId);

    const updateField = (ev) => {
        onChange({
            ...device,
            [ev.target.name]: ev.target.value
        })
    }

    function getMessage(key) {
        const msg = getErrorMessage(errors, key);
        return msg ? t(msg) : '';
    }

    return (
        <>
        <Grid container spacing={3} pt={1}>
            <Grid item xs={12}>
                <TextField
                    required
                    label={t('name')}
                    value={device.name}
                    name="name"
                    error={hasError(errors, "name")}
                    helperText={getMessage("name")}
                    onChange={updateField}
                    fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('brand')}
                    value={device.brand}
                    name="brand"
                    onChange={updateField}
                    fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('model')}
                    value={device.model}
                    name="model"
                    onChange={updateField}
                    fullWidth />
            </Grid>
        </Grid>
        <FieldList
            sx={{
                mt : 3,
                p: 2.5,
                borderRadius: 2,
                position: 'relative',
                bgcolor: 'grey.200'
            }}>
            <Field px={0} title={t('serialNumber')} value={device.serialNumber} />
            <Field px={0} title={t('gsmNumber')} value={<PhoneNumber value={device.gsmNumber}/>} />
            <Field px={0} title={t('plan')} value={plan ? plan.name : '-'} />
            <Field px={0} title={t('renewalDate')} value={<DateTime datetime={device.renewalDate} format="P" />} />
            <Field px={0} title={t('type')} value={<DeviceTypeLabel deviceTypeId={device.deviceTypeId} />} />
        </FieldList>
        </>
    );
}

const getEmptyDevice = () =>
({
    name: '',
    brand: '',
    model: '',
    planId: '',
    renewalDate: '',
    gsmNumber: '',
    serialNumber : ''
})


function Content({ id, onSaved, onClose }) {

    const [device, isFetching] = useDevice(id);
    const [updateDeviceForm, setUpdateDeviceForm] = useState(device || getEmptyDevice());
    const errors = useMemo(() => getErrors(updateDeviceForm), [updateDeviceForm]);
    const hasErrors = errors !== null;
    const [saveApi, isSaving] = useSaveApi();

    useEffect(() => setUpdateDeviceForm(device || getEmptyDevice()), [device]);

    const handleSave = async () => {
        const res = await saveApi(updateDevice(device.id, { name : updateDeviceForm.name, brand : updateDeviceForm.brand, model : updateDeviceForm.model }));
        if (res !== true) {
            onSaved && onSaved();
            onClose && onClose();
        }
    }

    return <React.Fragment>
        <DialogTitle>
            <Trans>editDevice</Trans>
        </DialogTitle>
        <DialogContent sx={{ position : 'relative' }}>
            {(isSaving || isFetching) && (<OverlapProgress progressProps={{ size : 75 }} />)}
            <Form device={updateDeviceForm} errors={errors} onChange={value => setUpdateDeviceForm(value)} />
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} disabled={isSaving} color="secondary">
                <Trans>close</Trans>
            </Button>
            <ButtonWithProgress
                onClick={handleSave}
                color="primary"
                loading={isSaving || isFetching}
                disabled={hasErrors || isSaving || !isDeviceActive(device)}>
                <Trans>save</Trans>
            </ButtonWithProgress>
        </DialogActions>
    </React.Fragment>
}

export default function EditDevice({ open, id, onSaved, onClose }) {

    return (
        <Dialog open={open} onClose={onClose} >
            <Content id={id} onClose={onClose} onSaved={onSaved} />
        </Dialog>
    );
}