import { useEffect, useRef, useState } from 'react';
import { Map as MapLeaflet, TileLayer } from "react-leaflet";

export default function Map({ children, center, zoom = 12, tileLayerProps = {}, ...props })
{
    const mapRef = useRef(null);
    const [zoomState, setZoomState] = useState(zoom);
    const [centerState, setCenterState] = useState(center);

    useEffect(() => setZoomState(zoom), [zoom]);

    useEffect(() => {
        if (mapRef.current) {
            setTimeout(() => mapRef.current.leafletElement.invalidateSize());
        }
    }, []);

    useEffect(() =>
    {
        setTimeout(() => {
            setCenterState(center)
        }, 500);

    }, [center]);

    return(
    <MapLeaflet
        ref={mapRef}
        center={centerState}
        zoom={zoomState}
        style={{ height: '100%', width : '100%' }}
        maxZoom={19}
        onViewportChange={({ zoom }) => setZoomState(zoom)}
        {...props}>
        <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxNativeZoom={19}
            minZoom={0}
            maxZomm={19}
            detectRetina={false}
            {...tileLayerProps} />
        {children}
    </MapLeaflet>);
}