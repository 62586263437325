import { IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import React from 'react'

export default function CloseIconButton({ title = 'Fechar', onClick, ...props }) {
    return (
        <Tooltip title={title} placement="top">
            <IconButton aria-label="fechar" onClick={onClick} {...props}>
                <CloseIcon />
            </IconButton>
        </Tooltip>
    )
}
