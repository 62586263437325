import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import { DeviceTypesIcons } from '@nvtracker/common/helpers';
import React from 'react'
import { useDeviceType } from '../../store/deviceTypes'

export default function DeviceTypeLabel({ deviceTypeId }) {

    const [deviceType] = useDeviceType(deviceTypeId);
    if (!deviceType) return '-';

    return (
        <Box display="flex" gap={1}>
            <Icon icon={DeviceTypesIcons[deviceType.type]} width={22} height={22} sx={{ mr: 1 }} />{deviceType.type}
        </Box>
    )
}
