import { ListItem, ListItemText } from "@mui/material";
import { CarIcon } from "@nvapps/common/components/ui/Icons";
import ListItemIconAvatar from "@nvapps/common/components/ui/ListItemIconAvatar";

export default function DeviceListItem({ children, device, ...props }) {

    const brandModel = [device.brand, device.model].filter(e=> e).join(' ');

    return (
        <ListItem {...props}>
            <ListItemIconAvatar iconComponent={CarIcon} />
            <ListItemText
                primary={device.name}
                secondary={brandModel} />
            {children}
        </ListItem>
    )
}