import { Avatar, Typography } from "@mui/material";
import CenteredContent from "./CenteredContent";

export default function EmptyScreenMessage({ iconComponent, message }) {
    const IconComp = iconComponent;
    return (
        <CenteredContent>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Avatar style={{background: "rgba(0,0,0,.66)", color: "rgba(255,255,255,0.66)", width: 110, height: 110, marginBottom: 10}}>
                    <IconComp style={{fontSize: 64}} />
                </Avatar>
                <Typography variant="h5">
                    {message}
                </Typography>
            </div>
        </CenteredContent>
    )
}