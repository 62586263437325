import axios from "axios";
import { appIdentifier } from '../../package.json';
import { usersApiBaseUrl } from "./utils";

export function login(credentials = { username: "", password: "" }) {

    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/login`,
        data: {...credentials, appToken : appIdentifier}
    });
}

export function forgotPassword(forgotPasswordForm = { username: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/forgotPassword`,
        data: forgotPasswordForm
    });
}

export function passwordRecovery(passwordRecoveryForm = { username: "", recoveryCode: "", password: "" }) {
    return axios({
        method: "POST",
        url: `${usersApiBaseUrl}/api/forgotPassword/recover`,
        data: passwordRecoveryForm
    });
}