import { Tooltip, Typography } from '@mui/material';
import React from 'react';

const SmsInfoText = React.forwardRef(({ credits, limit, tooltip, showWarning, threshold = 5, ...props }, ref) =>
{
    const overThreshold = limit > 0 && (limit - credits) < threshold;
    const color = overThreshold ? 'error.main' : null;

    return <Typography ref={ref} component="label" align="left" variant='inherit' color={color} {...props}>
        {credits}/{limit}
    </Typography>
});

export default function SmsInfo({ tooltip, placement="left", ...props }) {

    return Boolean(tooltip) ? <Tooltip title={tooltip} placement={placement}>
        <SmsInfoText {...props} />
    </Tooltip> : <SmsInfoText {...props} />
}
