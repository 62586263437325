import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function useFrozenRouteParam(refresh) {
    const params = useParams();
    const [frozenParam, setFrozenParam] = React.useState({});
    useEffect(() => {
        if (refresh) setFrozenParam(params);
    }, [params, refresh]);
    return frozenParam;
}

export const useReduxFetch = (action, isFecthingSelector, dataSelector, fetchErrorSelector) => {
    const dispatch = useDispatch();

    const fetch = useCallback(refetch => dispatch(action(refetch)), [dispatch, action]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const isFetching = useSelector(isFecthingSelector);
    const data = useSelector(dataSelector);
    const error = useSelector(fetchErrorSelector);

    return [data, isFetching, error, fetch];
}

export function useCallApi(props)
{
    const { successText, errorText, snackbarProps = {  } } = props || {};
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const callApi = async promise =>
    {
        setIsLoading(true);
        return await promise
        .then(e=>
        {
            enqueueSnackbar(successText || t('operationSuccess', { defaultValue : 'Operação efectuada com sucesso' }), { variant : 'success', ...snackbarProps});
            setIsLoading(false);
            return Promise.resolve({ success : true, data : e ? e.data : null });
        })
        .catch(error =>
        {
            const errorServer = error.response && error.response.data && error.response.data.error;
            enqueueSnackbar(errorText || (errorServer && t(`server_${errorServer}`)) || t('error', { defaultValue : 'Erro' }), { variant : 'error', ...snackbarProps});
            setIsLoading(false);
            return Promise.resolve(false);
        });
    }

    return [ callApi, isLoading ]
}

export function useSaveApi(props)
{
    const { t } = useTranslation();
    const { successText } = props || {};
    const [callApi, isSaving] = useCallApi(
    {
        ...props,
        successText : successText || t('recordSaved', { defaultValue : 'Registo guardado' })
    });

    return [callApi, isSaving];
}


export function useDeleteApi(props)
{
    const { t } = useTranslation();
    const { successText } = props || {};
    const [callApi, isLoading] = useCallApi(
    {
        ...props,
        successText : successText || t('recordDeleted', { defaultValue : 'Registo eliminado' })
    });

    return [callApi, isLoading];
}
