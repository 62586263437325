import React from "react";
import { CircularProgress } from "@mui/material";
import CenteredContent from "./CenteredContent";

export default function FullScreenProgress({ progressProps = {}, ...props}) {
    return (
        <CenteredContent {...props}>
            <CircularProgress {...progressProps} />
        </CenteredContent>
    );
}