import { Icon } from '@iconify/react';
import { Box, Button, Drawer, Skeleton, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import CenteredContent from '@nvapps/common/components/ui/CenteredContent';
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import FullScreenDialog from '@nvapps/common/components/ui/FullScreenDialog';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { useIsMobile } from '@nvapps/common/hooks';
import { getCurrencySymbol } from '@nvapps/common/utils';
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import SelectPlan from '@nvtracker/common/components/ui/SelectPlan';
import { canRenewDevice } from '@nvtracker/common/devices';
import { getRenewalCountryInfo } from '@nvtracker/common/helpers';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { cancelPurchase } from '../../../api/nvtracker';
import PaymentModal from '../../../modals/PaymentModal';
import { useDevice, useDeviceRenewalPermission } from '../../../store/devices';
import { usePlansOfGsmNumber } from '../../../store/plans';
import BillingInformationStep from './BillingInfoStep';
import { PaymentStep } from './PaymentStep';

function RenewDeviceContent({ id, onClose }) {
    const [device, isFetching] = useDevice(id);
    const { name, brand, model } = device || {};
    const brandModel = [brand, model].filter(e => (e || '').trim().length > 0).join(' ');
    const [renewalPermission, isFetchingRenewalPermission] = useDeviceRenewalPermission(id);
    const renewalInfoText = getRenewalCountryInfo(renewalPermission ? renewalPermission.countryCode : '');
    const { canBeRenew } = renewalPermission || {};

    return <React.Fragment>
        <BoxInfo
            title={isFetching ? <Skeleton /> : name || '-'}
            subtitle={isFetching ? <Skeleton /> : brandModel || '-'} />
        {(isFetching || isFetchingRenewalPermission) ? <OverlapProgress progressProps={{ size : 100 }} /> : Boolean(canBeRenew) === false ? (
            <CenteredContent>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", color: theme => theme.palette.error.dark }}>
                    <Icon icon="mdi:cancel" height={100} />
                    <Typography variant="h5" mt={1} sx={{ color: theme => theme.palette.text.primary, textAlign : 'center' }}>
                        <Trans>thisDeviceCannotBeRenewSite</Trans>
                    </Typography>
                    {Boolean(renewalInfoText) &&
                    <Typography variant="body1" mt={1} sx={{ color: theme => theme.palette.text.primary }}>
                        <Trans>{renewalInfoText}</Trans>
                    </Typography>}
                </Box>
            </CenteredContent>
        ) : <RenewDeviceStepper device={device} renewalPermission={renewalPermission} onClose={onClose} />}
    </React.Fragment>;
}

function RenewDeviceStepper({ device, renewalPermission, onClose }) {

    const [activeStep, setActiveStep] = useState(0);
    const [planId, setPlanId] = useState(device ? device.planId : '');
    const [plans] = usePlansOfGsmNumber(device ? device.gsmNumber : null);
    const { t } = useTranslation();
    const plan = (plans || []).find(e => e.id === planId);
    const [billingInfo, setBillingInfo] = useState(null);
    const [purchase, setPurchase] = useState(null);
    const [done, setDone] = useState(false);
    const [canceled, setCanceled] = useState(false);

    const handleBillingInfoNext = value => {
        setBillingInfo(value);
        setActiveStep(2);
    }

    const handlePaymentNext = (purchase) => {
        setPurchase(purchase);
    };

    const handlePurchaseCancelation = () => {
        cancelPurchase(purchase.id);
        setCanceled(true);
    };

    useEffect(() => {
        if (done || canceled || (!purchase && !canRenewDevice(device))) {

            const timer = setTimeout(() => onClose(), 5000);
            return () => clearTimeout(timer);
        }
    }, [done, canceled, purchase, device, onClose]);

    if (!done && !canceled && purchase) {
        return (
            <PaymentModal
                open={true}
                purchase={purchase}
                onDone={() => setDone(true)}
                onCancel={handlePurchaseCancelation} />
        );
    }

    if (done) {
        return (
            <CenteredContent>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", color: theme => theme.palette.success.dark }}>
                    <Icon icon="ant-design:check-circle-outlined" height={100} />
                    <Typography variant="h5" mt={1} sx={{ color: theme => theme.palette.text.primary }}>
                        <Trans>thankYou</Trans>
                    </Typography>
                </Box>
            </CenteredContent>
        );
    }

    if (canceled) {
        return (
            <CenteredContent>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", color: theme => theme.palette.error.dark }}>
                    <Icon icon="mdi:cancel" height={100} />
                    <Typography variant="h5" mt={1} sx={{ color: theme => theme.palette.text.primary }}>
                        <Trans>purchaseCancelled</Trans>
                    </Typography>
                </Box>
            </CenteredContent>
        );
    }

    if (!purchase && !canRenewDevice(device)) {

        return (
            <CenteredContent>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", color: theme => theme.palette.warning.dark }}>
                    <Icon icon="ant-design:warning-outlined" height={100} />
                    <Typography variant="h5" mt={1} sx={{ color: theme => theme.palette.text.primary }}>
                        <Trans>thisDeviceCannotBeRenew</Trans>
                    </Typography>
                </Box>
            </CenteredContent>
        );
    }

    return <Stepper activeStep={activeStep} orientation="vertical" sx={{ mt: 2 }}>
        <Step>
            <StepLabel>
                <Trans>
                    selectPlan
                </Trans>
            </StepLabel>
            <StepContent>
                <Box py={2} >
                    <SelectPlan
                        required
                        label={t('plan')}
                        value={planId}
                        name="planId"
                        onChange={ev => setPlanId(ev.target.value)}
                        error={planId === ''}
                        plans={plans}
                        fullWidth />
                    <Button variant='contained' sx={{ mt: 2 }} size="medium" disabled={!Boolean(plan)} onClick={() => setActiveStep(1)}>
                        <Trans>next</Trans>
                        {plan && ` (${plan.price}${getCurrencySymbol(plan.currency)})`}
                    </Button>
                </Box>
            </StepContent>
        </Step>
        <Step>
            <StepLabel>
                <Trans>
                    billingInfo
                </Trans>
            </StepLabel>
            <StepContent>
                <Box py={2} >
                    <BillingInformationStep billingInfo={billingInfo} onBack={() => setActiveStep(0)} onNext={handleBillingInfoNext} />
                </Box>
            </StepContent>
        </Step>
        <Step>
            <StepLabel>
                <Trans>
                    payment
                </Trans>
            </StepLabel>
            <StepContent>
                <Box py={1} >
                    <PaymentStep
                        plan={plan}
                        device={device}
                        billingInfo={billingInfo}
                        renewalPermission={renewalPermission}
                        onNext={handlePaymentNext}
                        onBack={() => setActiveStep(1)} />
                </Box>
            </StepContent>
        </Step>
    </Stepper>
}

export default function RenewDevice({ id, open, onClose }) {

    const isMobile = useIsMobile();

    return (isMobile ?
        <FullScreenDialog
            open={open}
            title={<Trans>renewDevices</Trans>}
            onClose={onClose}
            closeButtonProps={{ component: RouterLink, to: `/app/devices/${id}` }}>
            <Box p={3}>
                <RenewDeviceContent id={id} onClose={onClose} />
            </Box>
        </FullScreenDialog> :
        <Drawer anchor={'right'}
            onClose={onClose}
            open={open}>
            <Box px={2} py={1} width={550}>
                <Box display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                    <Typography variant="h6" mb={2}>
                        <Trans>renewDevice</Trans>
                    </Typography>
                    <CloseIconButton title={<Trans>close</Trans>} onClick={onClose} />
                </Box>
                <RenewDeviceContent id={id} onClose={onClose} />
            </Box>
        </Drawer>
    )
}