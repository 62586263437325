import { useFetchV2 } from '@nvapps/common/hooks';
import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as userApi from '../api/profile';

export const getDefaultProfile = () =>
({
    userName: '',
    name: '',
    country: 'PT',
    language: 'pt',
    newsletter: false,
    phoneNumber: '',
    picture : null
})

const initialState = {

    profile:
    {
        data: getDefaultProfile(),
        fetching: false,
        fetched: false,
    },
    photo:
    {
        data: null,
        fetching: false,
    },
    // timeZones : { data : [], fetching : false, error : null }
}

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {

        fetchProfileStart(state, { payload }) {
            state.profile.fetching = true;
        },

        fetchProfileSuccess(state, { payload }) {
            state.profile.fetching = false;
            state.profile.fetched = true;
            state.profile.data = { ...payload };
        },

        fetchPhotoStart(state, { payload }) {
            state.photo.fetching = true;
        },

        fetchPhotoSuccess(state, { payload }) {
            state.photo.fetching = false;
            state.photo.data = payload;
        },

        updatePhotoSuccess(state, { payload }) {
            state.profile.data.picture = payload;
        },

        // fetchTimeZonesStart(state, { payload }) {
        //     state.timeZones.fetching = true;
        // },

        // fetchTimeZonesSuccess(state, { payload }) {
        //     state.timeZones.fetching = false;
        //     state.timeZones.data = payload;
        // },

        // fetchTimeZonesFailed(state, { payload }) {
        //     state.timeZones.fetching = false;
        //     state.timeZones.error = payload;
        // },
    }
});


export function useUserActions() {
    const dispatch = useDispatch();
    const actions = user.actions;

    return useMemo(() => {
        return {

            updateProfile: profile => dispatch(actions.fetchProfileSuccess(profile)),
            changePassword: v => userApi.changePassword(v),
            updatePhoto: async p => userApi.updatePicture(p).then(res => dispatch(actions.updatePhotoSuccess(res ? res.data : null))),
            clearPhoto: () => actions.fetchPhotoSuccess(null),

            resendValidationEmail : async () => userApi.resendValidationEmail(),
            validateEmail : async validationCode => userApi.validateEmail(validationCode).then(res => dispatch(fetchProfile(true)))
        };
    }, [dispatch, actions]);
}

function fetchProfile(reload) {
    const actions = user.actions;

    return (dispatch, getState) => {
        var { user } = getState();

        if (!reload && (user.profile.fetching || user.profile.fetched)) return;

        dispatch(
            {
                type: actions.fetchProfileStart.type,
                meta:
                {
                    debounce: 200,
                    apiRequest:
                    {
                        method: "GET",
                        url: userApi.getProfileUrl,
                        successActionType: actions.fetchProfileSuccess.type
                    },
                    cancelApiRequest:
                    {
                        startedBy: actions.fetchProfileStart.type
                    }
                }
            });
    };
}

export function useUserProfile(reload) {
    const state = useSelector(state => state.user.profile);
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(fetchProfile(reload))
    }, [dispatch, reload]);

    return { profile: state.data, fetching: state.fetching };
}

function fetchPhoto(reload) {
    const actions = user.actions;

    return (dispatch, getState) => {

        const { user } = getState();

        if (user.photo.fetching) return;

        dispatch(actions.fetchPhotoStart());
        userApi.getProfilePhoto(user.profile.data.picture).then(res => {
            dispatch(actions.fetchPhotoSuccess(res));
        }).catch(res => {
            dispatch(actions.fetchPhotoSuccess(null));
        })
    };
}

export function useProfilePhoto() {

    const state = useSelector(state => state.user.photo);
    const picture = useSelector(state => state.user.profile.data.picture)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPhoto());
    }, [dispatch, picture]);

    return { photo: state.data, fetching: state.fetching };
}

export function useProfileLanguage()
{
    const state = useSelector(state => state.user.profile.data.language);
    return state || 'pt';
}

export function useUserBillingInfo() {
    return useFetchV2(() => userApi.getBillingInformation(), []);
}

// function fetchTimeZones()
// {
//     return (dispatch, getState) => {

//         dispatch(user.actions.fetchTimeZonesStart());
//         return userApi.getTimeZones().then(
//             res => {
//                 dispatch(user.actions.fetchTimeZonesSuccess(res.data));
//             },
//             err => dispatch(user.actions.fetchTimeZonesFailed(err))
//         );
//     }
// }


// function isFetchingTimeZones(state) {
//     return state.user.timeZones.fetching;
// }

// function timeZonesFetchError(state) {
//     return state.user.timeZones.error;
// }

// function timeZonesSelector(state)
// {
//     return state.user.timeZones.data;
// }

// export const useTimeZones = () => {

//     return useReduxFetch(fetchTimeZones, isFetchingTimeZones, timeZonesSelector, timeZonesFetchError);
// }

// export const useTimeZonesForSelect = () =>
// {
//     const [data, loading] = useTimeZones();
//     const res = useMemo(() => indexDataBy((data || []).map(e=> ({ value : e.id, label : e.name })), 'value'), [data]);
//     return [res, loading];
// }

export default user.reducer;