import { Box, Typography } from '@mui/material';
import React from 'react';

export default function BoxInfo({ title, subtitle, subtitle2, sx = {}, ...props }) {
    return (
        <Box sx={{ minWidth: 200, ...sx }} {...props}>
            <Typography variant="h4" >
                {title}
            </Typography>
            {subtitle &&
            <Typography variant="subtitle2" color="text.secondary">
                {subtitle}
            </Typography>}
            {subtitle2 && <Typography variant="subtitle2" >
                {subtitle2}
            </Typography>}
        </Box>
    )
}
