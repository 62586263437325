import { Typography } from '@mui/material';
import Label from '@nvapps/common/components/ui/Label';
import { ContractStatusEnum, getStatusColorOfContract, getStatusOfContract, getStatusTextOfContract } from '@nvtracker/common/helpers';
import { useTranslation } from 'react-i18next';

export function getDeviceContractStatusText(renewalDate) {
    const status = getStatusOfContract(renewalDate);
    return status === ContractStatusEnum.ACTIVE ? getStatusTextOfContract(renewalDate) : status === ContractStatusEnum.EXPIRED ? 'dates.expired' : 'dates.expires';
}

export default function ContractCell({ renewalDate }) {

    const { t } = useTranslation();;
    const text = renewalDate ? t(getDeviceContractStatusText(renewalDate), { date: new Date(renewalDate) }) : '-';

    return <Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
        <Typography variant="subtitle2" noWrap>
            {text}
        </Typography>
    </Label>;
}
