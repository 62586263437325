import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import { Icon } from '@iconify/react';
import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Chip, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import EmptyScreenMessage from '@nvapps/common/components/ui/EmptyScreenMessage';
import { Field, FieldList } from '@nvapps/common/components/ui/Fields';
import { MapIcon } from '@nvapps/common/components/ui/Icons';
import Label from '@nvapps/common/components/ui/Label';
import OverlapProgress from '@nvapps/common/components/ui/OverlapProgress';
import { PhoneNumber } from '@nvapps/common/components/ui/PhoneInput';
import SmsInfo from '@nvapps/common/components/ui/SmsInfo';
import { useIsMobile } from '@nvapps/common/hooks';
import CarMarker from '@nvtracker/common/components/map/CarMarker';
import Map from '@nvtracker/common/components/map/Map';
import BoxInfo from '@nvtracker/common/components/ui/BoxInfo';
import { canRenewDevice, isDeviceActive } from '@nvtracker/common/devices';
import { getStatusColorOfContract, getStatusOfPosition } from '@nvtracker/common/helpers';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import EditDevice from '../../components/Device/EditDevice';
import RenewDevice from '../../components/Device/RenewDevice';
import { getDeviceContractStatusText } from '../../components/Ui/ContractCell';
import DateTime from '../../components/Ui/DateTime';
import DeviceTypeLabel from '../../components/Ui/DeviceTypeLabel';
import { isDeviceExpired, useDevice } from '../../store/devices';
import { useDevicesPosition } from '../../store/devicesPositions';
import { usePlanById } from '../../store/plans';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkIcon = styled(Icon)({
    animation: `${blink} 1s linear infinite`,
});


function Content({ deviceId }) {

    const { t } = useTranslation();
    const [device, isFetching] = useDevice(deviceId);
    const { name, brand, model, gsmNumber, serialNumber, renewalDate, planId, equipmentVersion, hasPendingPurchase, deviceTypeId, smsCredits, smsLimit, smsDate } = device || {};
    const [plan] = usePlanById(planId);
    const brandModel = [brand, model].filter(e => (e || '').trim().length > 0).join(' ');
    const contractStatusText = renewalDate ? t(getDeviceContractStatusText(renewalDate), { date: new Date(renewalDate) }) : '-';
    const [position, isFetchingPosition] = useDevicesPosition(deviceId);
    const expiredDevice = isDeviceExpired(device);
    const activeDevice = isDeviceActive(device);
    const hasMap = !expiredDevice && position;
    const mapCenter = position ? position.coordinates : [41.3088979, -8.7362078];
    const { address, status, stateEndTime } = position || {};
    const isMobile = useIsMobile();


    return (
        <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <BoxInfo
                    title={isFetching ? <Skeleton /> : name || '-'}
                    subtitle={isFetching ? <Skeleton /> : brandModel || '-'}
                    subtitle2={<Box mt={0.5}>
                        {activeDevice ?
                            <Label variant="ghost" color={getStatusColorOfContract(renewalDate)}>
                                {contractStatusText}
                            </Label> : <Label variant="ghost" color='error'>
                                <Trans>disabled</Trans>
                            </Label>}
                    </Box>} />
                <Button
                    variant="outlined"
                    component={RouterLink}
                    to="/app/devices/list"
                    startIcon={<Icon icon={arrowBackFill} />}>
                    <Trans>
                        back
                    </Trans>
                </Button>
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }} mt={2}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    <Grid item xs={12} md={4} height={isMobile ? null : '100%'}>
                        <Card sx={{ height: '100%', display: "flex", flexDirection: "column" }}>
                            <CardHeader title={t('info')} />
                            <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                                <FieldList>
                                    <Field px={0} title={t('renewalDate')} value={<DateTime datetime={renewalDate} format="PPP" />} />
                                    <Field px={0} title={t('plan')} value={plan ? plan.name : '-'} />
                                    <Field px={0} title={t('serialNumber')} value={serialNumber} />
                                    <Field px={0} title={t('gsmNumber')} value={<PhoneNumber value={gsmNumber} />} />
                                    <Field px={0} title={t('smsBalance')} value={<SmsInfo credits={smsCredits} limit={smsLimit} date={smsDate} tooltip={<div>
                                    <Trans>lastUpdate</Trans>
                                        <br/>
                                        <DateTime datetime={smsDate} format="PPPpp" />
                                    </div>} />} />
                                    <Field px={0} title={t('equipmentVersion')} value={equipmentVersion || '-'} />
                                    <Field px={0} title={t('type')} value={<DeviceTypeLabel deviceTypeId={deviceTypeId} />} />
                                </FieldList>
                                <Box mt='auto' pt={2}>
                                    {hasPendingPurchase &&
                                        <Chip
                                            icon={<BlinkIcon icon="ant-design:warning-outlined" height={24} width={24} />}
                                            component={RouterLink}
                                            to="/app/purchases/list"
                                            label={<Typography variant='subtitle2'>
                                                <Trans>pendingPurchase</Trans>
                                            </Typography>}

                                            sx={{ mb: 1, width: '100%', p: 2, cursor : 'pointer' }}
                                            color="warning"
                                        />
                                    }
                                    <ButtonGroup fullWidth variant='text'>
                                        <Button fullWidth color="primary" disabled={!activeDevice || isFetching} component={RouterLink} to="edit" startIcon={<Icon icon="eva:edit-fill" width={24} height={24} />}>
                                            <Trans>
                                                edit
                                            </Trans>
                                        </Button>
                                        <Button fullWidth color="primary" disabled={!canRenewDevice(device) || isFetching} component={RouterLink} to="renew" startIcon={<Icon icon="ant-design:calendar-outlined" width={24} height={24} />}>
                                            <Trans>
                                                renew
                                            </Trans>
                                        </Button>
                                    </ButtonGroup>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card sx={{ height: '100%', minHeight: 500, position: 'relative' }}>
                            {isFetchingPosition && <OverlapProgress sx={{ zIndex: 999 }} progressProps={{ size: 75 }} />}
                            {position && <Box sx={{ background: "#ffffffc7", px: 2, py: 1, m: 1, left: 45, position: 'absolute', zIndex: 999, borderRadius: 2, display: 'grid', gridTemplateColumns: '1fr auto', gridGap: 12, right: 0 }}>
                                <Typography variant="subtitle2" mt={0.5} display="block">{address || 'N/D'}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                    <Chip sx={{ fontWeight: 600, mr: 1 }} label={<DateTime datetime={stateEndTime} />} size="small" variant="filled" />
                                    <Label color={getStatusOfPosition(status).color}>
                                        {t(getStatusOfPosition(status).text)}
                                    </Label>
                                </Box>
                            </Box>}
                            <Map zoom={hasMap ? 16 : 2} center={mapCenter}>
                                {hasMap && <CarMarker position={position} />}
                            </Map>
                            {!hasMap && <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 998, background: '#ffffffb8' }}><EmptyScreenMessage s
                                iconComponent={MapIcon}
                                message={
                                    <React.Fragment>
                                        <Trans>locationNotAvailable</Trans>
                                    </React.Fragment>
                                } />
                            </Box>
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default function Device({ renew = false, edit = false }) {

    const { id } = useParams();
    const navigate = useNavigate();

    return <React.Fragment>
        <Content deviceId={id} />
        <RenewDevice id={id} open={renew} onClose={() => navigate(`/app/devices/${id}`)} />
        <EditDevice id={id} open={edit} onClose={() => navigate(`/app/devices/${id}`)} />
    </React.Fragment>;
}