import { createSlice } from '@reduxjs/toolkit';
import { getDeviceTypesUrl } from '../api/nvtracker';
import { useReduxFetch } from '../hooks';

const initialState = {
    isFetching: false,
    fetched: false,
    error: null,
    byId: {},
    allIds: []
};

const deviceTypes = createSlice({
    name: 'deviceTypes',
    initialState,
    reducers: {
        fetchStart(state) {
            state.isFetching = true;
        },
        fetchFailed(state, action) {
            state.isFetching = false;
            state.error = action.payload;
        },
        fetchSuccess(state, action) {
            state.isFetching = false;
            state.fetched = true;
            state.allIds = action.payload.allIds
            state.byId = action.payload.byId
        }
    }
});

export function fetchDeviceTypes() {

    const actions = deviceTypes.actions;

    return async (dispatch, getState) => {

        const state = getState();
        if (state.deviceTypes.isFetching || state.deviceTypes.fetched) return;

        dispatch(
        {
            type: actions.fetchStart.type,
            meta:
            {
                debounce: 200,
                apiRequest:
                {
                    method: "GET",
                    url: getDeviceTypesUrl,
                    successActionType: actions.fetchSuccess.type,
                    errorActionType : actions.fetchFailed.type,
                    normalizeBy : 'id'
                },
                cancelApiRequest:
                {
                    startedBy: actions.fetchStart.type
                }
            }
        });
    }
}


export function getDeviceType(state, id) {
    const deviceTypes = state.deviceTypes;
    return deviceTypes.byId[id];
}


export function getDeviceTypes(state) {
    const deviceTypes = state.deviceTypes;
    return deviceTypes.allIds.map(id => deviceTypes.byId[id]);
}

export function isFetchingDeviceTypes(state) {
    return state.deviceTypes.isFetching;
}

export function deviceTypesFetchError(state) {
    return state.deviceTypes.error;
}

export const useDeviceTypes = (selector = getDeviceTypes) => {
    return useReduxFetch(fetchDeviceTypes, isFetchingDeviceTypes, selector, deviceTypesFetchError);
}

export const useDeviceType = (id) => {
    return useDeviceTypes(state => getDeviceType(state, id));
}

export default deviceTypes.reducer;