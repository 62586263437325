import { Icon } from "@iconify/react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useFetch } from "../../hooks";
import { getCurrencySymbol } from "../../utils";
import { locales } from "../../utils/dateUtils";
import { Field, FieldList } from "../ui/Fields";
import { MultibancoIcon } from "../ui/Icons";


function MultibancoPaymentContent({ payment, isLoading, translations, locale = 'pt', currency }) {

    const { entity, reference, value, paymentDeadline, error } = translations || {};
    const currencySym = currency && getCurrencySymbol(currency);

    if (isLoading) return <Box textAlign="center"><CircularProgress /></Box>
    if (Boolean(!payment)) return <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', color: theme => theme.palette.error.dark }}>
        <Icon icon="mdi:alert" height={100} />
        <Typography variant="h5" mt={1} >
            {error}
        </Typography>
    </Box>

    return <>
        <Stack spacing={2} direction="row">
            <MultibancoIcon style={{ fontSize: "80px" }} />
            <FieldList spacing={0.5}>
                <Field title={entity || 'Entity'} value={payment.entity} />
                <Field title={reference || 'Reference'} value={payment.reference} />
                <Field title={value || 'Value'} value={`${payment.amount}${(currencySym ? ` ${currencySym}` : '')}`} />
            </FieldList>
        </Stack>
        <Divider sx={{ my: 1.5 }} />
        <Box textAlign="center">
            <Typography variant="subtitle1" display="inline">
                {paymentDeadline || 'Payment deadline'}:
            </Typography>
            <Typography variant="body1" ml={1} display="inline">
                {format(new Date(payment.expires), "d MMMM yyyy", { locale: locales[locale] })}
            </Typography>
        </Box>
    </>
}

export function MultibancoPaymentInfo({ fetchPaymentDetails, translations, locale = 'pt', currency })
{
    const { data: payment, isLoading } = useFetch(() => fetchPaymentDetails(), []);
    return <MultibancoPaymentContent
        payment={payment}
        currency={currency}
        isLoading={isLoading}
        translations={translations}
        locale={locale} />
}

export default function MultibancoPaymentModal({ open, onDone, onClose, fetchPaymentDetails, translations, locale = 'pt', currency }) {

    const { data: payment, isLoading } = useFetch(() => fetchPaymentDetails(), []);
    const { mbPayment, ok } = translations || {};

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {mbPayment || 'Multibanco Payment'}
            </DialogTitle>
            <DialogContent>
                <MultibancoPaymentContent
                    payment={payment}
                    currency={currency}
                    isLoading={isLoading}
                    translations={translations}
                    locale={locale} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onDone} color="primary" disabled={isLoading} autoFocus>
                    {ok || 'OK'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}