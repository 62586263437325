import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

let stripeInstance;

function getStripeInstance() {
    if (!stripeInstance) 
        stripeInstance = loadStripe('pk_test_51N8Nz6HoKzPEQoCQLraIrZwKaXDKwfUlq44LWURfHMyAsA50Hx0hMC7E3du3EQBPngulvvJpjJUtXTBPbkEgbXh300Zw9Jb3XR');
    return stripeInstance;
}

export default function StripeContextProvider({ children }) {
    const stripe = getStripeInstance();
    return (
        <Elements stripe={stripe}>
            {children}
        </Elements>
    );
}