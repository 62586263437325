import {
    Box, DialogContent, DialogTitle, Stack, Typography
} from "@mui/material";
import CloseIconButton from '@nvapps/common/components/ui/CloseIconButton';
import Dialog from "@nvapps/common/components/ui/Dialog";
import OverlapProgress from "@nvapps/common/components/ui/OverlapProgress";
import Map from '@nvtracker/common/components/map/Map';
import BoxInfo from "@nvtracker/common/components/ui/BoxInfo";
import React from "react";
import { useTranslation } from "react-i18next";
import { Marker } from "react-leaflet";
import { useParams } from "react-router-dom";
import DateTime from "../components/Ui/DateTime";
import { useEvent } from "../store/events";

function Content({ onClose }) {

    const { id } = useParams();
    const [event, isFetching] = useEvent(id);
    const { deviceName, deviceBrand, deviceModel, latitude, longitude, eventTypeName, eventTypeBody, dateTime } = event || {};
    const position = [latitude, longitude];
    const deviceBrandModel = [deviceBrand, deviceModel].filter(e => (e || '').trim().length > 0).join(' ');
    const { t } = useTranslation();

    return <React.Fragment>
        {isFetching && <OverlapProgress progressProps={{ size: 100 }} />}
        <DialogTitle>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                {eventTypeName || '-'}
                <CloseIconButton title={t('close')} onClick={onClose} />
            </Stack>
        </DialogTitle>
        <DialogContent sx={{ mt: -2 }}>
            <Stack gap={2}>
                <BoxInfo
                    title={deviceName || '-'}
                    subtitle={deviceBrandModel} />
                <Box>
                    <Typography variant="subtitle2" >
                        {eventTypeBody}
                    </Typography>
                    <Typography variant="caption" >
                        <DateTime datetime={dateTime} format="PPPpp" />
                    </Typography>
                </Box>
                <Box sx={{ overflow: 'hidden', height: 500 }} borderRadius={1}>
                    {event &&
                        <Map center={position} zoom={16}>
                            <Marker position={position} />
                        </Map>}
                </Box>
            </Stack>
        </DialogContent>
    </React.Fragment>
}

export default function EventDialog({ open, onClose, event }) {

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            onClose={onClose}>
            <Content onClose={onClose} />
        </Dialog>
    );
}