import { Icon } from '@iconify/react';
import { Avatar, Box, Button, ButtonGroup, Typography } from '@mui/material'
import { styled } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react'

const Input = styled('input')({
    display: 'none',
});

const AvatarWrapper = styled('div')(({ theme }) => ({
    border: `1px dotted ${theme.palette.grey[300]}`,
    borderRadius: '50%',
    padding: theme.spacing(1),
    position: 'relative',
    cursor: 'pointer',
    margin: '0 auto',
    '& > label':
    {
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity .2s, visibility .2s'
    },
    '&:hover > label':
    {
        opacity: 1,
        visibility: 'visible'
    }

}));

const AvatarTop = styled(Avatar)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    flexDirection: 'column',
    backgroundColor: '#0000008f',
    cursor: 'pointer'
}));

export default function UploadAvatar({
    id = 'upload-photo',
    size = 48,
    src = null,
    updatePhotoText = 'Update photo',
    subtitleText,
    saveButtonText = 'Save',
    removeButtonText = 'Remove',
    onChange,
    onSave,
    onRemove
}) {

    const [_image, _setImage] = useState(src);
    const [file, setFile] = useState();
    const inputFileRef = useRef(null);

    useEffect(() => setImage(src), [src]);

    const cleanup = () => {
        if (_image) URL.revokeObjectURL(_image);
        inputFileRef.current.value = null;
    };

    const setImage = (newImage) => {
        cleanup();
        _setImage(newImage);
    };

    const handleOnChange = (event) => {
        const newImage = event.target?.files?.[0];
        if (newImage) {
            setImage(URL.createObjectURL(newImage));
            setFile(newImage);
        }
    };

    const clear = () => {
        _setImage(null);
        setFile(null);
    }

    useEffect(() => {
        return () => clear()
    }, []);

    const handleRemove = () => {
        clear();
        onRemove && onRemove();
    }

    const handleSave = () => {
        onSave && onSave(file);
        clear();
    }

    return (
        <Box display="flex" flexDirection="column" textAlign="center" >
            <AvatarWrapper >
                <Avatar alt="user" src={_image} sx={{ height: size, width: size }} />
                <label htmlFor={id}>
                    <Input ref={inputFileRef} accept="image/*" id={id} type="file" onChange={handleOnChange} />
                    <AvatarTop sx={{ height: size, width: size, }}>
                        <Icon icon="ic:baseline-add-a-photo" height={20} width={20} />
                        {updatePhotoText &&
                            <Typography variant="body1" fontSize={'0.65rem'} mt={1}>
                                {updatePhotoText}
                            </Typography>}
                    </AvatarTop>
                </label>
            </AvatarWrapper>
            {subtitleText &&
                <Typography variant="caption" mt={1} color="text.secondary">
                    {subtitleText}
                </Typography>}
            <ButtonGroup variant="outlined" sx={{ mt: 2, mx: 'auto' }}>
                <Button onClick={handleSave} size="small" disabled={!file}>
                    {saveButtonText}
                </Button>
                <Button size="small" onClick={handleRemove} disabled={!file && !_image}>
                    {removeButtonText}
                </Button>
            </ButtonGroup>
        </Box>
    )
}
