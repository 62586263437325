import React from 'react';
import leaflet from 'leaflet';
import { Marker } from "react-leaflet";
import carTopViewIconUrl from '../../assets/car-top-view.png';

export default function CarMarker({ position }) {
    const carIcon = React.useMemo(() => leaflet.divIcon({
        html: `<img src='${carTopViewIconUrl}' style='width: 36px; height: 68px; transform: rotate(${position.direction}deg)' />`,
        className: "",
        iconSize: [36, 68]
    }), [position.direction]);

    console.log('position', position.coordinates)

    return (
        <Marker position={position.coordinates} icon={carIcon}>
        </Marker>
    );
}