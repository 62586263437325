
import { Icon } from '@iconify/react';
import { Box, Button, Card, Container, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { SearchBox } from '@nvapps/common/components/ui/SearchBox';
import TableToolbar from '@nvapps/common/components/ui/TableToolbar';
import { useDebounce } from '@nvapps/common/hooks';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DateTime from '../../components/Ui/DateTime';
import EventDialog from '../../modals/EventDialog';
import { useEvents, useEventsActions, useEventsFilters } from '../../store/events';

export default function Events({ detailsOpen }) {

    const [events, isLoading] = useEvents();
    const { updateFilters, fetchEvents } = useEventsActions();
    const { searchText, page, pageSize, sortBy, sortDesc, rowCount } = useEventsFilters();
    const [searchQuery, setSearchQuery] = useState(searchText);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useDebounce(() => {
        updateFilters({ searchText: searchQuery, page: 0 });
    }, 200, [searchQuery])

    const handleSort = sortModel => {
        if (sortModel && sortModel.length > 0) updateFilters({ sortBy: sortModel[0].field, sortDesc: sortModel[0].sort === 'desc' });
        else updateFilters({ sortBy: null, sortDesc: false });
    };

    const columns = [{
        field: 'Id',
        headerName: t('id'),
        width: 80,
        renderCell: ({ row: { id } }) =>
            <Typography variant="subtitle2" noWrap>
                {id}
            </Typography>,
    },
    {
        field: 'DeviceName',
        headerName: t('device'),
        width: 250,
        renderCell: ({ row: { deviceName, deviceBrand, deviceModel } }) =>
            <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${deviceName}`}
                </Typography>
                <Typography variant="caption" noWrap >
                    {[deviceBrand, deviceModel].filter(e => e).join(' ')}
                </Typography>
            </Box>
    },
    {
        field: 'EventTypeName',
        headerName: t('event'),
        flex: 0.3,
        minWidth: 150,
        renderCell: ({ row: { eventTypeName, eventTypeBody } }) =>
            <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {`${eventTypeName}`}
                </Typography>
                <Typography variant="caption" noWrap >
                    {eventTypeBody}
                </Typography>
            </Box>
    },
    {
        field: 'DateTime',
        headerName: t('dateTime'),
        width: 150,
        renderCell: ({ row: { dateTime } }) => <Box style={{ lineHeight: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                    <DateTime datetime={dateTime} format='PP' />
                </Typography>
                <Typography variant="caption" noWrap >
                    <DateTime datetime={dateTime} format='pp' />
                </Typography>
            </Box> ,
    },
    {
        field: 'options',
        sortable: false,
        renderHeader: _ => <div></div>,
        renderCell: ({ row }) => <Tooltip title={t('seeDetails')} placement="top">
            <IconButton>
                <Icon icon="eva:search-outline" width={20} height={20} />
            </IconButton>
        </Tooltip>,
        align: 'right',
        width: 70
    }];


    return <React.Fragment>
        <Container maxWidth="xl" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                <Typography variant="h4" >
                    <Trans>events</Trans>
                </Typography>
            </Stack>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <TableToolbar>
                    <SearchBox
                        value={searchQuery}
                        onChange={ev => setSearchQuery(ev.target.value)}
                        placeholder={`${t('search')}...`} />
                    <Button
                        sx={{ ml: 'auto' }}
                        variant="outlined"
                        onClick={() => fetchEvents()}
                        startIcon={<Icon icon="eva:refresh-fill" />}>
                        <Trans>refresh</Trans>
                    </Button>
                </TableToolbar>
                <Box sx={{ flex: 1, width: '100%' }}>
                    <DataGrid
                        rows={events}
                        columns={columns}
                        pageSize={pageSize}
                        page={page}
                        rowsPerPageOptions={[5, 10, 25]}
                        rowCount={rowCount}
                        loading={isLoading}
                        sortModel={sortBy ? [{ field: sortBy, sort: sortDesc ? 'desc' : 'asc' }] : []}
                        density="comfortable"
                        paginationMode="server"
                        sortingMode="server"
                        disableColumnMenu
                        onRowClick={({ row }) => navigate(`/app/events/${row.id}`)}
                        hideFooterSelectedRowCount
                        pagination
                        onSortModelChange={handleSort}
                        onPageChange={page => updateFilters({ page })}
                        onPageSizeChange={pageSize => updateFilters({ pageSize })}
                    />
                </Box>
            </Card>
        </Container>
        <EventDialog open={Boolean(detailsOpen)} onClose={() => navigate('/app/events/list')} />
    </React.Fragment>
}