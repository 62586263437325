import { authRequest } from "./utils";

const apiBaseUrl = "";

export const getPlansUrl = `${apiBaseUrl}/api/plans`;

export function getPlansByGsmNumber(gsmNumber) {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/plans/gsm?gsmNumber=${encodeURIComponent(gsmNumber)}`
    });
}

export function getPlansForRenewal() {
    return authRequest({
        method: "GET",
        url: `${apiBaseUrl}/api/plans/renewal`
    });
}