import { Icon } from '@iconify/react';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

export function SearchBox({ value = '', onChange, placeholder= 'Pesquisar...', ...props })
{
    return <SearchStyle
        value={value}
        size="small"
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={
            <InputAdornment position="start">
                <Box component={Icon} icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
        }
        {...props}
    />
}

export function SearchTextField({ value, placeholder, onChange, ...props   })
{
   return <OutlinedInput
        value={value}
        size="small"
        fullWidth
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={
            <InputAdornment position="start">
                <Box component={Icon} icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
        }  {...props}/>
}