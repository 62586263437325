import { useFetchV2 } from '@nvapps/common/hooks';
import { urlEncode } from '@nvapps/common/utils';
import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as nvtrackerApi from '../api/nvtracker';

const initialState = {
    list :
    {
        page : 0,
        pageItems : [],
        pageSize : 10,
        rowCount : 0,
        pageCount : 0
    },
    filters :
    {
        searchText : '',
        sortBy : 'DateTime',
        sortDesc : true,
        page : 0,
        pageSize : 10,
    },
    loading: false,
    hasError: true
};

const events = createSlice({
    name: 'events',
    initialState,
    reducers: {
        fetchStart(state) {
            state.loading = true;
            state.hasError = false;
        },
        fetchFailed(state) {
            state.hasError = true;
            state.loading = false;
        },
        fetchSuccess(state, { payload }) {
            state.list = payload;
            state.loading = false;
        },
        updateFilters(state, { payload }) {
            state.filters = { ...state.filters, ...payload };
        },
    }
});

function fetchEvents() {

    const actions = events.actions;

    return (dispatch, getState) =>
    {
        const { events: { filters } } = getState();

        dispatch(
        {
            type: actions.fetchStart.type,
            meta:
            {
                debounce: 200,
                apiRequest:
                {
                    method: "GET",
                    url: nvtrackerApi.getEventsPagedUrl,
                    successActionType: actions.fetchSuccess.type,
                    errorActionType : actions.fetchFailed.type,
                    params: {...filters, searchText : urlEncode(filters.searchText)}
                },
                cancelApiRequest:
                {
                    startedBy: actions.fetchStart.type
                }
            }
        });
    };
}

export function useEvents() {
    const { pageItems } = useSelector(state => state.events.list);
    const loading = useSelector(state => state.events.loading);
    const filters = useSelector(state => state.events.filters, shallowEqual);
    const { fetchEvents } = useEventsActions();

    useEffect(() =>
    {
        fetchEvents();
    }, [filters, fetchEvents]);

    return [pageItems, loading];
}

export function useEventsLoading() {

    const state = useSelector(state => state.events.loading);
    return state;
}

export function useEventsFilters() {

    const filters = useSelector(state => state.events.filters, shallowEqual);
    const rowCount = useSelector(state => state.events.list.rowCount);

    return {...filters, rowCount};
}

export function useEventsActions() {
    const dispatch = useDispatch();
    const actions = events.actions;

    return useMemo(() => ({
        fetchEvents: () => dispatch(fetchEvents()),
        updateFilters : value => dispatch(actions.updateFilters(value))
    }), [dispatch, actions]);
}

export const useEvent = id => {
    return useFetchV2(id ? () => nvtrackerApi.getEvent(id) : null, [id]);
}


export default events.reducer;